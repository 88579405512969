import React from 'react';
import { Table, Button } from 'react-bootstrap';

const GameDetail = ({ game, goBack }) => {
    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <h3>Game Details for {game.gameType}</h3>
                <Button variant="secondary" onClick={goBack} style={{ marginBottom: '15px' }}>
                    Go Back
                </Button>
            </div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Game ID</th>
                        <th>{game.gameType == 'Zodiac' ? 'Rashi' : game.gameType}</th>
                        <th>No. of Users</th>
                        <th>Total Bet Amount</th>
                        <th>Total Winning Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {game.details.zodiacCounts && Object.keys(game.details.zodiacCounts).map((sign) => (
                        <tr key={sign}>
                            <td>{game.gameId}</td>
                            <td>{sign}</td>
                            <td>{game.details.zodiacCounts[sign]}</td>
                            <td>₹{game.details.zodiacAmounts[sign]}</td>
                            <td>₹{game.winner.toString() == sign.toString() ? game.details.zodiacAmounts[sign] * 9 : game.details.zodiacWins[sign]}</td>
                        </tr>
                    ))}
                    {game.details.numberCounts && Object.keys(game.details.numberCounts).map((number) => (
                        <tr key={number}>
                            <td>{game.gameId}</td>
                            <td>{number}</td>
                            <td>{game.details.numberCounts[number]}</td>
                            <td>₹{game.details.numberAmounts[number]}</td>
                            <td>₹{game.winner == number ? game.details.numberAmounts[number] * 9 : game.details.numberWins[number]}</td>
                        </tr>
                    ))}
                    {game.details.colorCounts && Object.keys(game.details.colorCounts).map((color) => (
                        <tr key={color}>
                            <td>{game.gameId}</td>
                            <td>{color}</td>
                            <td>{game.details.colorCounts[color]}</td>
                            <td>₹{game.details.colorAmounts[color]}</td>
                            <td>₹{game.winner == color ? game.details.colorAmounts[color] * 9 : game.details.colorWins[color]}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default GameDetail;