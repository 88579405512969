import React, { useState } from 'react';
import { TextField, Box, Typography, Container, Avatar, CssBaseline, Paper } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Form, Button, Spinner } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    // setPasswordValid(passwordValue.length >= ); // Example: Password must be at least 6 characters
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailValid && passwordValid) {
      setIsLoading(true);
      console.log("Form submitted:", { email, password });
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "username": email,
          "password": password
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        var response = await fetch("https://sikandar.in/admin/login-user", requestOptions);
        if (response.ok) {
          const data = await response.json();
          const { token, user } = data;
          localStorage.setItem('isLoggedInAdmin', 'true');
          localStorage.setItem('user', 'false');
          localStorage.setItem('token', token);
          localStorage.setItem('userId', user._id);
          window.location.reload();
          setIsLoading(false);
        } else {
          // If response is not ok, show alert for incorrect username or password
          localStorage.setItem('loggedIn', 'false');
          setIsLoading(false);
          alert("Incorrect username or password. Please try again.");
          console.error('Login failed');
        }
      } catch (error) {
        console.error('Error during login:', error);
        localStorage.setItem('loggedIn', 'false');
        setIsLoading(false);
        alert("An error occurred during login. Please try again later.");
      }
    } else {
      alert("Please fill in all fields correctly.");
    }
  };

  const validateEmail = (email) => {
    // Simple email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  return (<Container component="main" maxWidth="xs" sx={{ borderRadius: 2, height: '92vh' }}>
    <CssBaseline />
    <Paper elevation={6} sx={{ padding: 4, marginTop: 8, borderRadius: 2 }}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
        <img style={{ margin: '10px', padding: '0px', width: '100px', }} src={require('../../assets/colored_logo.png')} alt="Sikandar Logo" />
        <Typography component="h1" variant="h5" style={{ fontFamily: "Noto Sans" }} >
          Sikandar Admin Login
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <Form style={{ fontFamily: "Noto Sans" }} className="w-100" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontWeight: '500', borderRadius: '20px' }}>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                className="w-100"
                style={{ width: '100%', borderRadius: '20px' }}
                value={email}
                onChange={handleEmailChange}
                isInvalid={email !== '' && !emailValid}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="inputPassword5" style={{ fontWeight: '500', borderRadius: '20px' }}>Password</Form.Label>
              <Form.Control
                type="password"
                id="inputPassword5"
                aria-describedby="passwordHelpBlock"
                style={{ width: '100%', borderRadius: '20px' }}
                value={password}
                onChange={handlePasswordChange}
                isInvalid={password !== '' && !passwordValid}
              />
              <Form.Control.Feedback type="invalid">
                Password must be at least 9 characters long.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              style={{ fontWeight: '600', borderRadius: '20px', marginTop: '15px' }}
              elevation={3}
              className="w-100"
              variant="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : null}  Login
            </Button>
          </Form>
        </Box>
      </Box>
    </Paper>
  </Container>
  );
};

export default Login;