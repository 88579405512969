import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const RefundScreen = () => {
    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
            <h5 style={{ fontSize: '24px', margin: '10px' }} className='footer-links-row'>Refund Policy</h5>
            <p className='footer-links-row' style={{ fontSize: '18px', textAlign: 'center' }} >
                At Sikandar Game, customer satisfaction is of utmost importance to us. Please note that all transactions made within the game are final and non-refundable. In the unlikely event of a technical issue or an error in the transaction process, please contact our support team at [gamesikandar@gmail.com](mailto:gamesikandar@gmail.com), and we will do our best to resolve the issue promptly. All refund requests will be reviewed on a case-by-case basis.
            </p>
        </div>
    </div>
    );
};

export default RefundScreen;