import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const WithdrawalList = () => {
    const [withdrawals, setWithdrawals] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;

    // Fetch withdrawals from the API
    const fetchWithdrawals = async () => {
        const response = await fetch('https://sikandar.in/api/withdrawals'); // Replace with actual API
        const data = await response.json();
        setWithdrawals(data.reverse());
    };

    useEffect(() => {
        fetchWithdrawals();
    }, []);

    // Filter based on search term
    const filteredWithdrawals = withdrawals.filter(withdrawal =>
        withdrawal.user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination for withdrawals
    const totalPages = Math.ceil(filteredWithdrawals.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const currentWithdrawals = filteredWithdrawals.slice(startIndex, startIndex + itemsPerPage);

    const handleProcessWithdrawal = async (id, action) => {
        const response = await fetch(`https://sikandar.in/api/withdraw/approve/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: action }),
        });

        if (response.ok) {
            // Update local state to reflect the change
            const updatedWithdrawals = withdrawals.map(withdrawal =>
                withdrawal._id === id ? { ...withdrawal, status: action } : withdrawal
            );
            setWithdrawals(updatedWithdrawals);
            alert(`Withdrawal ${action}d successfully!`);
            fetchWithdrawals();
        } else {
            const errorMsg = await response.text();
            alert(`Error processing withdrawal: ${errorMsg}`);
        }
    };

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const exportData = () => {
        // Implement CSV/Excel export functionality here
        console.log("Data exported as CSV/Excel");
    };

    return (
        <Container fluid style={{ width: '100%', height: '100vh' }} className="d-flex flex-column py-4">
            <h2 className="mb-4 text-center">Withdrawal Requests</h2>

            <Form className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Form>

            <Row className="flex-grow-1" style={{ overflow: 'scroll' }}>
                <Col>
                    <Table striped bordered hover responsive className="w-100">
                        <thead className="table-dark">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>Amount Requested</th>
                                <th>Wallet Balance</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentWithdrawals.map((withdrawal) => (
                                <tr key={withdrawal._id}>
                                    <td>{withdrawal.user._id}</td>
                                    <td>{withdrawal.user.name}</td>
                                    <td>{withdrawal.user.mobile}</td>
                                    <td>₹{withdrawal.amount}</td>
                                    <td>₹{withdrawal.user.balance}</td>
                                    <td>
                                        <span className={withdrawal.status === 'approved' ? 'text-success' : 'text-warning'}>
                                            {withdrawal.status.charAt(0).toUpperCase() + withdrawal.status.slice(1)}
                                        </span>
                                    </td>
                                    <td>
                                        {withdrawal.status === 'pending' && (
                                            <>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    onClick={() => handleProcessWithdrawal(withdrawal._id, 'approved')}
                                                >
                                                    Approve
                                                </Button>
                                                <div style={{ width: '10px', height: '10px' }} />
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleProcessWithdrawal(withdrawal._id, 'rejected')}
                                                    className="ml-2"
                                                >
                                                    Reject
                                                </Button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {/* Pagination Controls */}
            <div className="pagination-controls d-flex justify-content-between mt-3">
                <Button
                    variant="secondary"
                    onClick={() => handlePageChange('prev')}
                    disabled={currentPage === 0}
                >
                    Previous
                </Button>
                <span>{`Page ${currentPage + 1} of ${totalPages}`}</span>
                <Button
                    variant="secondary"
                    onClick={() => handlePageChange('next')}
                    disabled={currentPage === totalPages - 1}
                >
                    Next
                </Button>
                <Button variant="success" onClick={exportData}>
                    Export Data (CSV/Excel)
                </Button>
            </div>
        </Container>
    );
};

export default WithdrawalList;