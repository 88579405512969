import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const FooterLinks = () => {
  return (
    <div className="footer-links-row" style={{ display: "flex" }}>
      <a href="/about-us" className="footer-link">About Us</a>
      <a href="/contact-us" className="footer-link">Contact Us</a>
      <a href="/refund-policy" className="footer-link">Refund Policy</a>
      <a href="/privacy-policy" className="footer-link">Privacy Policy</a>
      <a href="/terms-and-conditions" className="footer-link">Terms and Conditions</a>
    </div>
  );
};

export default FooterLinks;
