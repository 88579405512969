import React, { useState, useEffect } from 'react';
import './ColorScreen.css';
import { useNavigate } from 'react-router-dom';
import AmountPopup from './AmountPopup';
import profile from '../assets/coin.png'; // Path to your logo image
import { Alert } from 'react-bootstrap'; // Import Alert component
import SpinnerWheel from './SpinnerWheel';
// import './RotatingImage.css'; // Create this CSS file for keyframe animations

const COLORS = [
    'मेष', 'वृषभ', 'मिथुन', 'कर्क', 'सिंह', 'कन्या',
    'तुला', 'वृश्चिक', 'धनु', 'मकर', 'कुंभ', 'मीन'
];

function ZodiacScreen({ onScreenChange }) {
    const navigate = useNavigate(); // Hook for navigation
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [betAmount, setBetAmount] = useState(0);
    const [gameID, setGameID] = useState('');
    const [betPlaced, setBetPlaced] = useState(false);
    const [timeLeft, setTimeLeft] = useState(300); // 10 minutes in seconds
    const [winningColor, setWinningColor] = useState(null);
    const [winHistory, setWinHistory] = useState([]); // State to track win history
    const [endTime, setEndTime] = useState(null);
    const [balance, setBalance] = useState(null);
    const [chosenNumbers, setChosenNumbers] = useState([]);
    const [chosenItems, setChosenItems] = useState([]);
    const [totalBetAmount, setTotalBetAmount] = useState(0);
    const [popupVisible, setPopupVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertWinner, setShowAlertWinner] = useState(false);
    const [showAlertBalance, setShowAlertBalance] = useState(false);
    const [winner, setWinner] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [showNoMoreBets, setShowNoMoreBets] = useState(false);
    const [shortTimer, setShortTimer] = useState(false);
    const [amount, setAmount] = useState('');
    const [angle, setAngle] = useState(0); // The angle where rotation will stop
    const [isRotating, setIsRotating] = useState(false); // Tracks whether the image is rotating
    const [targetAngle, setTargetAngle] = useState(0); // The angle where user wants to stop the image
    const [winnerAlert, setWinnerAlert] = useState('');
    // 1. Configure the wheel's properties:
    const props = {
        items: [
            {
                label: 'one',
            },
            {
                label: 'two',
            },
            {
                label: 'three',
            },
        ]
    }

    // Handle amount input change
    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleSpin = () => {
        setIsSpinning(true);
        setTimeout(() => {
            setIsSpinning(false);
        }, 5000); // spin for 5 seconds
    };

    useEffect(() => {
        let timer;
        if (showAlert) {
            // Set a timer to hide the alert after 5 seconds
            timer = setTimeout(() => setShowAlert(false), 5000);

        }
        // Cleanup timer on component unmount or when showAlert changes
        return () => clearTimeout(timer);
    }, [showAlert]);

    useEffect(() => {
        let timer;
        if (showNoMoreBets) {
            // Set a timer to hide the alert after 5 seconds
            timer = setTimeout(() => setShowNoMoreBets(false), 5000);

        }
        // Cleanup timer on component unmount or when showAlert changes
        return () => clearTimeout(timer);
    }, [showNoMoreBets]);

    useEffect(() => {
        let timer;
        if (showAlertWinner) {
            // Set a timer to hide the alert and reload the window after 5 seconds
            timer = setTimeout(() => {
                setShowAlertWinner(false);
                window.location.reload();
            }, 10000);
        }
        // Cleanup timer on component unmount or when showAlert changes
        return () => clearTimeout(timer);
    }, [showAlertWinner]);


    useEffect(() => {
        let timer;
        if (showAlertBalance) {
            timer = setTimeout(() => setShowAlertBalance(false), 5000);
        }
        return () => clearTimeout(timer);
    }, [showAlertBalance]);

    const handlePopupClose = () => {
        setPopupVisible(false); // Hide the popup
    };

    const handleAmountSubmit = (amount) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("chosen", selectedColor); // Use selectedNumber
        setSelectedColor('');
        urlencoded.append("betAmount", amount.toString()); // Convert amount to string

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch(`https://sikandar.in/api/place-bet/${localStorage.getItem('userId')}/${gameID}`, requestOptions)
            .then(response => {
                if (response.status === 400) {
                    setShowAlertBalance(true);
                    return response.text(); // Still handle response text to avoid issues
                } else if (response.ok) {
                    return response.text();
                } else {
                    throw new Error('Unexpected response status');
                }
            })
            .then(result => {
                setPopupVisible(false); // Hide the popup after submission
                fetchBetAmount(gameID);
                fetchChosenNumbers(gameID);
                fetchUserBalance();
                setShowAlert(true);
            })
            .catch(error => console.error('Error placing bet:', error));
    };

    const fetchBetAmount = async (gameID) => {
        //     try {
        //         const response = await fetch(`https://sikandar.in/api/bets/total/${gameID}`);
        //         const data = await response.json();
        //         setTotalBetAmount(data.totalBetAmount);
        //     } catch (error) {
        //         console.error('Error fetching total bet amount:', error);
        //     }
    };

    const fetchChosenNumbers = async (gameID) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        try {
            const response = await fetch(`https://sikandar.in/bets/${localStorage.getItem('userId')}/${gameID}`, requestOptions);
            const result = await response.json();

            // Set the chosen items
            setChosenItems(result);

            // Extract and reverse the chosen numbers
            const numbers = result.map(bet => bet.chosen);
            setChosenNumbers(numbers.reverse());

            // Calculate the total betAmount
            const totalBetAmount = result.reduce((sum, bet) => sum + bet.betAmount, 0);
            setTotalBetAmount(totalBetAmount);
        } catch (error) {
            console.error("Error fetching bets:", error);
        }
    };

    const fetchUserBalance = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch(`https://sikandar.in/users/${localStorage.getItem('userId')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // Extract balance from the response and update state
                setBalance(result.balance);
            })
            .catch(error => console.error('Error fetching user data:', error));
    }


    useEffect(() => {
        const fetchAndUpdateGameData = async () => {
            try {
                const response = await fetch('https://sikandar.in/api/games/active');
                const data = await response.json();
                console.log(data);
                const numberGame = data.find(game => game.gameType === 'Zodiac');
                if (numberGame) {
                    const endTime = new Date(numberGame.endTime);
                    fetchUserBalance();
                    fetchBetAmount(numberGame._id);
                    fetchChosenNumbers(numberGame._id);
                    setGameID(numberGame._id);
                    setEndTime(endTime);

                    // Update the timer initially
                    const updateTimer = () => {
                        const now = new Date();
                        const timeDifference = endTime - now;

                        if (Math.ceil((timeDifference / 1000)) > 30) {
                            setTimeLeft(Math.ceil((timeDifference / 1000))); // Convert milliseconds to seconds
                        }
                        else if (Math.ceil((timeDifference / 1000)) <= 30 && Math.ceil((timeDifference / 1000)) > 10) {
                            setTargetAngle(1440);
                            setIsRotating((result) => {
                                return true;
                            });
                            setTimeLeft(Math.ceil((timeDifference / 1000))); // Convert milliseconds to seconds
                        }
                        else {
                            setTimeLeft(Math.ceil((timeDifference / 1000))); // Convert milliseconds to seconds
                            const requestOptions = {
                                method: "GET",
                                redirect: "follow"
                            };
                            fetch("https://sikandar.in/api/game-history/Zodiac", requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    // Find the game with the specified ID
                                    const game = data.find(game => game._id === numberGame._id);

                                    if (game) {
                                        let winningItem = '';
                                        setChosenItems((chosenItems) => {
                                            winningItem = chosenItems.find(item => item.chosen === game.winning);
                                            if (winningItem) {
                                                setWinnerAlert(`You have won. Your amount is ₹${winningItem.betAmount * 9}!`);
                                                setShowAlertWinner(true);
                                            }
                                            else {
                                                setWinnerAlert(`Better luck next time!`);
                                                setShowAlertWinner(true);
                                            }
                                            return chosenItems;
                                        });
                                        if (game.winning === 'मीन') {
                                            setTargetAngle(1455);
                                            setShortTimer(true);
                                        } else if (game.winning === 'कर्क') {
                                            setTargetAngle(1485);
                                            setShortTimer(true);
                                        } else if (game.winning === 'वृषभ') {
                                            setTargetAngle(1515);
                                            setShortTimer(true);
                                        } else if (game.winning === 'वृश्चिक') {
                                            setTargetAngle(1545);
                                            setShortTimer(true);
                                        } else if (game.winning === 'कन्या') {
                                            setTargetAngle(1575);
                                            setShortTimer(true);
                                        } else if (game.winning === 'मकर') {
                                            setTargetAngle(1605);
                                            setShortTimer(true);
                                        } else if (game.winning === 'कुंभ') {
                                            setTargetAngle(1635);
                                            setShortTimer(true);
                                        } else if (game.winning === 'मेष') {
                                            setTargetAngle(1665);
                                            setShortTimer(true);
                                        } else if (game.winning === 'तुला') {
                                            setTargetAngle(1695);
                                            setShortTimer(true);
                                        } else if (game.winning === 'सिंह') {
                                            setTargetAngle(1725);
                                            setShortTimer(true);
                                        } else if (game.winning === 'मिथुन') {
                                            setTargetAngle(1755);
                                            setShortTimer(true);
                                        } else if (game.winning === 'धनु') {
                                            setTargetAngle(1785);
                                            setShortTimer(true);
                                        }
                                        setWinner(game.winning);

                                        setIsRotating(false);
                                        // setIsSpinning(false);
                                    } else {
                                        return null;
                                    }
                                })
                                .catch(error => console.error('Error fetching game history:', error));
                            // } else {
                            // fetchAndUpdateGameData();
                            // setTimeLeft(0);
                            // const randomNumber = NUMBERS[Math.floor(Math.random() * NUMBERS.length)];
                            // setWinningNumber(randomNumber);
                            // setShowNotification(true);
                            // setTimeout(() => {
                            //     setShowNotification(false);
                            //     fetchAndUpdateGameData(); // Fetch new game data after restart
                            // }, 5000);
                        }
                    };

                    // Initial call and set interval for updating the timer
                    const timer = setInterval(updateTimer, 1000);
                    updateTimer(); // Initial call to set the timer immediately
                    return () => clearInterval(timer);
                }
            } catch (error) {
                console.error('Error fetching game data:', error);
            }
        };

        fetchAndUpdateGameData();
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch("https://sikandar.in/api/game-history/Zodiac", requestOptions)
            .then(response => response.json())
            .then(data => setWinHistory(data.map(game => game.winning)))
            .catch(error => console.error('Error fetching game history:', error));
    }, []);

    const handleNumberSelect = (number) => {
        setSelectedColor(number);
    };

    // Trigger rotation animation when rotation starts
    useEffect(() => {
        if (isRotating) {
            const timeout = setTimeout(() => {
                setIsRotating(false); // Stop rotating after 10 seconds
                setAngle(targetAngle); // Set the image to the user-defined angle
            }, 10000); // 10 seconds

            return () => clearTimeout(timeout); // Cleanup the timeout on unmount or when rotation changes
        }
    }, [isRotating, targetAngle]);

    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />

            <div style={{
                alignItems: 'space-evenly',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#F6F6F6',
                padding: '0 10px', padding: '0px'
            }}>
                <div style={{ width: '10px' }} />
                <div style={{
                    alignItems: 'start',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <img style={{ margin: '10px', padding: '0px', width: '20px', height: '20px' }} src={profile} alt="Sikandar Logo" />
                        <div style={{
                            alignItems: 'start',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            Amount: ₹{balance !== null ? balance : 'Loading...'}
                            <p
                                onClick={() => {
                                    navigate('/account');
                                }}
                                style={{
                                    margin: '0px',
                                    padding: '0px',
                                    fontWeight: 'bold',
                                    color: 'blue',
                                    fontSize: '12px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'  // This adds a pointer cursor to indicate it's clickable
                                }}
                            >
                                My Account
                            </p>
                        </div>
                    </p>
                    <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1.2em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        Winner: {winner}
                    </p>
                </div>
                <div style={{ flex: 1 }} />
                <div style={{
                    alignItems: 'end',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}>
                    <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        Bet Amount: ₹{totalBetAmount}
                    </p>
                    <Timer endTime={endTime} />
                </div>
                <div style={{ width: '10px' }} />
            </div>
            <div style={{ height: '10px' }} />
            <RotatingImage endTime={endTime} targetAngle={targetAngle} shortTimer={shortTimer} />
            {/* <SpinnerWheel isSpinning={isSpinning} /> */}
            <ColorSelector
                colors={COLORS}
                selectedColor={selectedColor}
                onSelect={handleNumberSelect}
                selectedAmount={amount}
                onAmountSelect={handleAmountChange}
                disabled={timeLeft < 30 || timeLeft > 120}
                chosenNumbers={chosenNumbers}
                winHistory={winHistory} // Pass winHistory here
            />
            {timeLeft < 30 || timeLeft > 120 ? <button
                disabled={timeLeft < 30 || timeLeft > 120} // Button will disable if timeLeft is less than 20
                onClick={() => {
                }}
                style={{
                    borderRadius: '5px',
                    padding: '10px 50px',
                    backgroundColor: 'grey',
                    color: 'white',
                    border: 'none',
                }}
            >
                <p style={{ margin: 0, fontWeight: 'bold' }}>Submit</p>
            </button> : <button
                disabled={timeLeft < 30 || timeLeft > 120} // Button will disable if timeLeft is less than 20
                onClick={() => {
                    if (chosenNumbers.length >= 10) {
                        setShowNoMoreBets(true);
                    } else {
                        if (selectedColor && amount) {
                            handleAmountSubmit(amount);
                        } else {
                            alert('Please select rashi and enter your bet amount!');
                        }
                    }
                }}
                style={{
                    borderRadius: '5px',
                    padding: '10px 50px',
                    backgroundColor: 'blue',
                    color: 'white',
                    border: 'none',
                }}
            >
                <p style={{ margin: 0, fontWeight: 'bold' }}>Submit</p>
            </button>}

            {/* {popupVisible && (
                <AmountPopup
                    onClose={handlePopupClose}
                    onSubmit={handleAmountSubmit}
                />
            )} */}
            <div style={{
                border: '2px solid blue',
                padding: '10px',
                margin: '10px',
                borderRadius: '10px', textAlign: 'left'
            }}>
                <p style={{ margin: '0 0 0 0', padding: '0px', fontWeight: 'bolder' }}>Rashi Representation</p>
                <p style={{ margin: '0', padding: '0px', fontSize: '20px', fontWeight: 'bold', color: 'maroon' }}>
                    {chosenNumbers.join(', ')}
                </p>
                <WinHistory winHistory={winHistory} />
            </div>

            <div style={{ width: '100%', padding: '0 10px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <button
                    onClick={() => {
                        navigate('/color');
                    }}
                    style={{
                        borderRadius: '5px',
                        padding: '10px 50px',
                        backgroundColor: 'white', // Use 'lightblue' for pale blue
                        color: 'black', // Ensure text is visible on the pale blue background
                        border: 'none', // Optional: remove border for a cleaner look
                    }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Color Game</p>
                </button>
                <button
                    onClick={() => {
                        navigate('/number');
                    }}
                    style={{
                        borderRadius: '5px',
                        padding: '10px 50px',
                        backgroundColor: 'green',
                        color: 'white', // Ensure text is visible on the grey background
                        border: 'none', // Optional: remove border for a cleaner look
                    }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Number Game</p>
                </button>
            </div>
        </div >

        {showAlert && (
            <Alert
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                }}
            >
                Bet has been successfully placed!
            </Alert>
        )}
        {showAlertWinner && (
            <Alert
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                }}
            >{winnerAlert}</Alert>
        )}
        {showAlertBalance && (
            <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                    transition: 'opacity 0.5s ease-out',
                }}>You have insufficient balance. Kindly recharge your wallet.
            </Alert>
        )}
        {showNoMoreBets && (
            <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                    transition: 'opacity 0.5s ease-out',
                }}>No more bets please!
            </Alert>
        )}
        {timeLeft <= 30 && timeLeft > 25 ? <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
            style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 1050,
                transition: 'opacity 0.5s ease-out',
            }}>No more bets please!
        </Alert> : null}
    </div>
    );


}

const RotatingImage = ({ endTime, targetAngle, shortTimer }) => {
    const [isRotating, setIsRotating] = useState(false); // State to control rotation
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Function to calculate time remaining in seconds
    function calculateTimeLeft() {
        const now = new Date().getTime();
        const timeDifference = Math.floor((endTime - now) / 1000); // Difference in seconds
        return timeDifference > 0 ? timeDifference : 0; // Ensure it's not negative
    }

    useEffect(() => {
        // Update the time left every second
        const timerInterval = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            if (newTimeLeft <= 30) {
                setIsRotating(true);
            }

            // Stop the timer when time reaches 0
            if (newTimeLeft <= 0) {
                clearInterval(timerInterval); // Stop the timer when it reaches 0
            }
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(timerInterval);
    }, [endTime]);

    return (
        <div style={{ justifyContent: 'center', display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            {/* Rotating Image */}
            <img style={{
                width: '80%',
                transform: isRotating ? `rotate(${targetAngle}deg)` : `rotate(0deg)`, // Rotate to the stop angle after 10 seconds
                transition: isRotating ? shortTimer ? 'transform 2s ease-in-out' : 'transform 23s ease-in' : 'none', // Smooth transition when stopping
                transformOrigin: '50% 50%',
            }}
                src={require('../assets/spinner.png')}
                alt="Spinner" />

            {/* Arrow Image */}
            <img style={{
                width: '10%',
            }}
                src={require('../assets/arrow-up.png')}
                alt="Arrow" />
        </div>
    );
};

const ColorSelector = ({ colors, selectedColor, onSelect, disabled, chosenNumbers, winHistory, selectedAmount, onAmountSelect }) => {
    // Determine the last 2 zodiac signs from winHistory
    const lastTwoWinHistory = winHistory.slice(0, 2);

    // Filter out the last 2 zodiac signs from the dropdown options
    const filteredColors = colors.filter(color => !lastTwoWinHistory.includes(color));

    return (
        <div>
            <div style={{ height: '10px' }} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label htmlFor="zodiac" style={{ marginRight: '10px', fontSize: '1em' }}>Select a Rashi:</label>
                    <select
                        id="zodiac"
                        onChange={(e) => onSelect(e.target.value)}
                        value={selectedColor || ""}
                        disabled={disabled}
                        style={{
                            width: '100%',
                            padding: '5px',
                            fontSize: '1em',
                            height: '2.5em', // Adjust height for consistency
                            boxSizing: 'border-box'
                        }}
                    >
                        <option value="" disabled>Select a rashi</option>
                        {filteredColors.map((color) => (
                            <option
                                key={color}
                                value={color}
                                disabled={disabled || chosenNumbers.includes(color)}
                            >
                                {color}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={{ width: '10px' }} />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label htmlFor="amount" style={{ marginRight: '10px', fontSize: '1em' }}>Enter Amount:</label>
                    <input
                        id="amount"
                        type="number"
                        value={selectedAmount}
                        onChange={onAmountSelect}
                        disabled={disabled}
                        style={{
                            width: '100%',
                            padding: '5px',
                            fontSize: '1em',
                            height: '2.5em',
                            boxSizing: 'border-box'
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const Timer = ({ endTime }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Function to calculate time remaining in seconds
    function calculateTimeLeft() {
        const now = new Date().getTime();
        const timeDifference = Math.floor((endTime - now) / 1000); // Difference in seconds
        return timeDifference > 0 ? timeDifference : 0; // Ensure it's not negative
    }

    useEffect(() => {
        // Update the time left every second
        const timerInterval = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);

            if (newTimeLeft <= 0) {
                clearInterval(timerInterval); // Stop the timer when it reaches 0
            }
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(timerInterval);
    }, [endTime]);

    // Calculate minutes and seconds
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    // Determine if the time left is more than 2 minutes (120 seconds)
    const isMoreThanTwoMinutes = timeLeft > 120;

    return (<p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1.2em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        {isMoreThanTwoMinutes ? 'Starts in' : 'Time'}:
        <div style={{ color: 'blue', fontWeight: 'bold', marginLeft: '5px' }}>
            {isMoreThanTwoMinutes ? '00' : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </div>
    </p>
    );
};


const Result = ({ selectedColor, winningColor, betAmount }) => {
    const isWin = selectedColor === winningColor;
    return (
        <div>
            <h2 className="result-title">Winning Color: {winningColor}</h2>
            <h3 className="result-text">You {isWin ? 'Win' : 'Lose'}!</h3>
            {isWin && <p className="result-win">You won ${betAmount * 10}!</p>}
        </div>
    );
};

const WinHistory = ({ winHistory }) => {
    return (
        <div>
            <p style={{ margin: '0 0 10px 0', padding: '0px', fontWeight: 'bolder' }}>Win History</p>
            <div className="win-history">
                {winHistory.length === 0 ? (
                    <p className="no-history">No history yet.</p>
                ) : (
                    winHistory.map((color, index) => (
                        <div key={index}
                            style={{
                                marginBottom: '10px',
                                backgroundColor: 'blue',
                                color: 'white',
                                padding: '5px',
                                margin: '5px',
                                borderRadius: '5px',
                                fontSize: '12px',
                                textAlign: 'center',
                            }}>
                            {color}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ZodiacScreen;