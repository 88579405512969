import React, { useState } from 'react';

const AmountPopup = ({ onClose, onSubmit }) => {
    const [customAmount, setCustomAmount] = useState('');

    const predefinedAmounts = [10, 20, 50, 100];

    const handleAmountClick = (amount) => {
        onSubmit(amount);
    };

    const handleCustomAmountChange = (e) => {
        setCustomAmount(e.target.value);
    };

    const handleSubmit = () => {
        if (customAmount && !isNaN(customAmount)) {
            onSubmit(parseFloat(customAmount));
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
            width: '350px',
            textAlign: 'center'
        }}>
            <h3 style={{ margin: '0 0 15px', fontSize: '18px', color: '#333' }}>Select Amount</h3>
            <div style={{ marginBottom: '15px' }}>
                {predefinedAmounts.map((amount) => (
                    <button
                        key={amount}
                        onClick={() => handleAmountClick(amount)}
                        style={{
                            margin: '5px',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                    >
                        ₹{amount}
                    </button>
                ))}
            </div>
            <input
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                placeholder="Custom Amount"
                style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    marginBottom: '15px',
                    fontSize: '16px'
                }}
            />
            <div>
                <button
                    onClick={handleSubmit}
                    style={{
                        margin: '5px',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        backgroundColor: '#28a745',
                        color: '#fff',
                        fontSize: '16px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#218838'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#28a745'}
                >
                    Submit
                </button>
                <button
                    onClick={onClose}
                    style={{
                        margin: '5px',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        backgroundColor: '#dc3545',
                        color: '#fff',
                        fontSize: '16px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#c82333'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#dc3545'}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default AmountPopup;