import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function LoginScreen() {
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            mobile: '',
            otp: '',
        },
        validationSchema: Yup.object({
            mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number is not valid').required('Mobile number is required'),
            otp: otpSent ? Yup.string().required('OTP is required') : Yup.string(),
        }),
        onSubmit: (values) => {
            if (otpSent) {
                // Use fetch to verify OTP
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("mobile", values.mobile);
                urlencoded.append("otp", values.otp);  // Using Formik's values.otp

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://sikandar.in/api/verify-otp", requestOptions)
                    .then((response) => response.json()) // Parse the JSON response
                    .then((result) => {
                        console.log(result);

                        if (result.message === "OTP verified. Login successful.") {
                            // Store user details in localStorage
                            localStorage.setItem('isLoggedIn', 'true');
                            localStorage.setItem('userId', result.user._id);
                            localStorage.setItem('userName', result.user.name);
                            localStorage.setItem('userMobile', result.user.mobile);

                            // Navigate to /welcome and remove the current screen from history
                            navigate('/welcome', { replace: true });
                        } else {
                            alert('Invalid or expired OTP, Please try again!');
                        }
                    })
                    .catch((error) => console.error('Error verifying OTP:', error));
            } else {
                // Use fetch to send OTP
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("mobile", values.mobile);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://sikandar.in/api/login", requestOptions)
                    .then((response) => response.status)
                    .then((result) => {
                        console.log('OTP verification result:', result);
                        if (result === 200) {
                            console.log('OTP sent result:', result);
                            setOtpSent(true);
                        } else {
                            alert('Please check your details! If you\'re creating a new account, kindly register!');
                        }
                    })
                    .catch((error) => console.error('Error sending OTP:', error));
            }
        },
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit} className="login-form">
                <h2 className="switch-screen-text" style={{ color: 'black', fontWeight: 'normal', fontSize: '20px' }}>Login to your account!</h2>
                <input
                    type="text"
                    name="mobile"
                    placeholder="Mobile Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                />
                {formik.touched.mobile && formik.errors.mobile ? <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>{formik.errors.mobile}</div> : null}

                {otpSent && (
                    <input
                        type="text"
                        name="otp"
                        placeholder="Enter OTP"
                        onChange={formik.handleChange}  // Updating Formik's values.otp
                        value={formik.values.otp}
                    />
                )}
                {formik.touched.otp && formik.errors.otp ? <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>{formik.errors.otp}</div> : null}

                <button className="play-button-login">{otpSent ? 'Verify OTP' : 'Send OTP'}</button>
            </form>
        </div>
    );
}

export default LoginScreen;