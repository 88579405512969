import { useEffect, useState } from 'react';
import { Wheel } from 'react-custom-roulette';

const zodiacSigns = [
    { option: '♈ Aries', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#FF6347' },       // Tomato Red
    { option: '♉ Taurus', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#8FBC8F' },     // Dark Sea Green
    { option: '♊ Gemini', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#FFD700' },     // Gold
    { option: '♋ Cancer', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#4682B4' },     // Steel Blue
    { option: '♌ Leo', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#FF4500' },        // Orange Red
    { option: '♍ Virgo', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#32CD32' },      // Lime Green
    { option: '♎ Libra', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#FF69B4' },      // Hot Pink
    { option: '♏ Scorpio', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#8A2BE2' },    // Blue Violet
    { option: '♐ Sagittarius', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#00CED1' },// Dark Turquoise
    { option: '♑ Capricorn', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#2F4F4F' },  // Dark Slate Gray
    { option: '♒ Aquarius', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#1E90FF' },   // Dodger Blue
    { option: '♓ Pisces', style: { fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", fontWeight: 'normal' }, color: '#7B68EE' },     // Medium Slate Blue
];

const SpinnerWheel = ({ isSpinning }) => {
    const [isSpin, setIsSpin] = useState(isSpinning);

    useEffect(() => {
        setIsSpin(isSpinning);
    },
        [isSpinning]);

    return <Wheel
        mustStartSpinning={isSpin}
        prizeNumber={5}
        data={zodiacSigns}
        backgroundColors={['#FF6347', '#8FBC8F', '#FFD700', '#4682B4', '#FF4500', '#32CD32', '#FF69B4', '#8A2BE2', '#00CED1', '#2F4F4F', '#1E90FF', '#7B68EE']}
        outerBorderWidth={0}
        radiusLineWidth={1}
        radiusLineColor='white'
        spinDuration={1}
        disableInitialAnimation={true}
        textColors={['#ffffff']}
    />
}

export default SpinnerWheel;