import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const TermsScreen = () => {
    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
            <h5 style={{ fontSize: '24px', margin: '10px' }} className='footer-links-row'>Terms and Conditions</h5>
            <p className='footer-links-row' style={{ fontSize: '18px', textAlign: 'center' }} >
                1. Overview:
                Sikandar Game offers three thrilling betting games: Colour Game, Number Game, and Rashi Game. By participating, you agree to abide by these terms and conditions.
                <br />
                2. Eligibility:
                Players must be of legal gambling age as determined by their jurisdiction to participate in any games.
                <br />
                3. Winning & Prizes:
                For each game, the winning prize is nine times the bet amount. Winnings will be credited to the player's account after the game round concludes.
                <br />
                4. Fair Play:
                We ensure a fair and transparent gaming experience. Any attempt to manipulate the outcome of the games will result in immediate disqualification and account termination.
                <br />
                5. Account & Security:
                Players are responsible for maintaining the confidentiality of their account information. Sikandar Game is not liable for any unauthorized access to your account.
                <br />
                6. Refund Policy:
                All transactions are final. Refunds are not provided unless in cases of technical errors, as detailed in our Refund Policy.
                <br />
                7. Privacy:
                We prioritize your privacy and protect your data according to our Privacy Policy.
                <br />
                8. Contact Us:
                For any issues, contact us at [gamesikandar@gmail.com](mailto:gamesikandar@gmail.com).
                <br />
                By accessing and playing on Sikandar Game, you agree to these terms and conditions.
            </p>
        </div>
    </div>
    );
};

export default TermsScreen;
