import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './UserList.css';

const UserList = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name');  // Sorting criteria
    const [currentPage, setCurrentPage] = useState(0);
    const usersPerPage = 10;

    // Fetch all users from the API on component mount
    useEffect(() => {
        fetchUsers();
    }, []);

    // Function to fetch users from the API
    const fetchUsers = async () => {
        try {
            const response = await fetch('https://sikandar.in/admin/users');
            const data = await response.json();
            setAllUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Filter and sort users based on the search term and sort criteria
    const filteredUsers = allUsers
        .filter(user =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (sortBy === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sortBy === 'accountBalance') {
                return b.balance - a.balance;
            } else if (sortBy === 'activityStatus') {
                return a.status.localeCompare(b.status);
            }
            return 0;
        });

    // Calculate total pages and current users for pagination
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
    const startIndex = currentPage * usersPerPage;
    const currentUsers = filteredUsers.slice(startIndex, startIndex + usersPerPage);

    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        } else if (direction === 'prev' && currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    // Function to toggle the status of the user between active and suspended
    const handleSuspendUser = async (id) => {
        try {
            const response = await fetch(`https://sikandar.in/admin/users/${id}/toggle-status`, {
                method: 'PATCH',
            });

            if (response.ok) {
                const updatedUser = await response.json();
                alert(`User with ID ${id} status changed to ${updatedUser.user.status}`);

                // Fetch updated users list after status change
                fetchUsers();
            } else {
                console.error('Error toggling user status');
            }
        } catch (error) {
            console.error('Error toggling user status:', error);
        }
    };

    const handleSendNotification = (id) => {
        // Add logic to send notification
        alert(`Notification sent to user with ID ${id}.`);
    };

    return (
        <Container fluid style={{ width: '98%', height: '85vh' }} className="d-flex flex-column">
            <h2 className="mb-4 text-center">User Management</h2>

            {/* Search and Sorting */}
            <Form className="mb-3 d-flex justify-content-between">
                <Form.Control
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="me-2"
                />
                <Form.Select value={sortBy} onChange={handleSortChange}>
                    <option value="name">Sort by Name</option>
                    <option value="accountBalance">Sort by Account Balance</option>
                    <option value="activityStatus">Sort by Activity Status</option>
                </Form.Select>
            </Form>

            <Row className="flex-grow-1" style={{ overflow: 'scroll' }}>
                <Col className="p-0">
                    <Table striped bordered hover responsive className="w-100 h-100">
                        <thead className="table-dark">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Number</th>
                                <th>Activity Status</th>
                                <th>Account Balance</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUsers.map((user) => (
                                <tr key={user._id}>
                                    <td>{user._id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.mobile}</td>
                                    <td>{user.status}</td>
                                    <td>₹{user.balance}</td>
                                    <td>
                                        <Button
                                            size="sm"
                                            className={`me-2 ${user.status === 'active' ? 'btn-danger' : 'btn-success'}`}
                                            onClick={() => handleSuspendUser(user._id)}
                                        >
                                            {user.status === 'active' ? 'Suspend' : 'Activate'}
                                        </Button>

                                        {/* <Button variant="info" size="sm" onClick={() => handleSendNotification(user._id)}>
                                            Notify
                                        </Button> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default UserList;