import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const ContactUsScreen = () => {
  return (<div style={{
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  }}>
    <div className="responsive-div" style={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    }}>
      <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
      <h5 style={{ fontSize: '24px', margin: '10px' }} className='footer-links-row'>Contact Us</h5>
      <p className='footer-links-row' style={{ fontSize: '18px', textAlign: 'center' }} >
        We're here to help! If you have any questions, feedback, or concerns, feel free to reach out to us. Your satisfaction is our top priority, and we're committed to providing you with the best possible support.
        Email: [gamesikandar@gmail.com](mailto:gamesikandar@gmail.com)
      </p>
    </div>
  </div>
  );
};

export default ContactUsScreen;