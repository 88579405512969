import React, { useState, useEffect } from 'react';
import './WelcomeScreen.css'; // Custom CSS file
import { useNavigate } from 'react-router-dom';
// Logo and Account Icons Import
import logo from '../assets/colored_logo.png'; // Path to your logo image
import profile from '../assets/coin.png'; // Path to your logo image
import hello from '../assets/goodbye.png'; // Path to your logo image
import FooterLinks from './FooterLinks';

const games = [
    { name: 'RASHI GAME', color: 'orange', countdown: 60, gameType: 'Zodiac' },
    { name: 'COLORS GAME', color: 'white', countdown: 300, gameType: 'Color' },
    { name: 'NUMBER GAME', color: 'green', countdown: 600, gameType: 'Number' }
];

function WelcomeScreen({ onScreenChange, onSelectGame }) {
    const [activeGames, setActiveGames] = useState([]);
    const [timers, setTimers] = useState({});
    const navigate = useNavigate(); // Hook for navigation
    const [balance, setBalance] = useState(null);

    const handleBack = () => {
        navigate('/account');
    };


    const handleGameClick = (gameType) => {
        switch (gameType) {
            case 'Zodiac':
                navigate('/zodiac');
                break;
            case 'Color':
                navigate('/color');
                break;
            case 'Number':
                navigate('/number');
                break;
            default:
                console.warn('Unknown game type');
        }
    };

    const handleExit = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate(-1, { replace: true });
    };


    useEffect(() => {
        const fetchActiveGames = async () => {
            try {
                const response = await fetch('https://sikandar.in/api/games/active');
                const data = await response.json();
                const updatedGames = data.map(game => {
                    const endTime = new Date(game.endTime);
                    return { ...game, endTime: endTime.toISOString() }; // Convert to ISO string for consistency
                });

                setActiveGames(updatedGames);
            } catch (error) {
                console.error('Error fetching active games:', error);
            }
        };

        fetchActiveGames(); // Fetch initially

        const interval = setInterval(() => {
            fetchActiveGames(); // Refresh every 30 seconds
        }, 5000); // 30000 ms = 30 seconds

        return () => clearInterval(interval); // Clean up on unmount
    }, []);

    const fetchUserBalance = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch(`https://sikandar.in/users/${localStorage.getItem('userId')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // Extract balance from the response and update state
                setBalance(result.balance);
            })
            .catch(error => console.error('Error fetching user data:', error));
    }

    useEffect(() => {
        fetchUserBalance();
    }, []);

    useEffect(() => {
        // Set up interval to update timers
        const interval = setInterval(() => {
            setTimers(prevTimers => {
                const updatedTimers = { ...prevTimers };

                activeGames.forEach(game => {
                    const remainingTime = Math.max(0, Math.floor((new Date(game.endTime) - new Date()) / 1000));
                    updatedTimers[game._id] = remainingTime;
                });

                return updatedTimers;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [activeGames]);

    const handleGameSelect = (game) => {
        onSelectGame(game);
    };

    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <div className="responsive-div" style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
            }}>
                <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={logo} alt="Sikandar Logo" />
                <div style={{
                    height: '60px',
                    fontSize: '20px',
                    margin: '10px',
                    padding: '0px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    display: 'flex',
                }}>
                    <div style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        <img style={{ margin: '10px', padding: '0px', width: '40px', height: '40px' }} src={hello} alt="Sikandar Logo" />
                        <p style={{
                            fontSize: '20px',
                            margin: '10px',
                            padding: '0px',
                            fontWeight: '500',
                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                            color: 'black'
                        }}>
                            Hi, {localStorage.getItem('userName')}
                        </p>
                    </div>
                    <div style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                    }}>
                        <img style={{ margin: '10px', padding: '0px', width: '40px', height: '40px' }} src={profile} alt="Sikandar Logo" />
                        <div style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                        }}>
                            <p style={{
                                fontSize: '20px',
                                padding: '0px',
                                margin: '0px',
                                padding: '0px',
                                fontWeight: '500',
                                fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                color: 'black'
                            }}>
                                ₹{balance}
                            </p>
                            <p style={{
                                fontSize: '12px',
                                padding: '0px',
                                margin: '0px',
                                fontWeight: '500',
                                color: 'blue',
                                fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                            }}>
                                Wallet
                            </p>
                        </div>
                    </div>
                </div>
                <div className="landing-content" style={{
                    margin: '10px',
                    padding: '0px',
                }}>
                    <div className="game-options" style={{ flexDirection: 'column', display: 'flex' }}>
                        {games.map(game => {
                            const gameData = activeGames.find(activeGame => activeGame.gameType === game.gameType);
                            console.log(gameData);

                            const remainingTime = gameData ? timers[gameData._id] : null;

                            return (
                                <div
                                    className={`game-option ${game.color}`}
                                    key={game.name}
                                    onClick={() => handleGameClick(game.gameType)}
                                >
                                    <h4>{game.name}</h4>
                                    {remainingTime ?
                                        <p style={{
                                            fontSize: '16px',
                                            margin: '10px',
                                            padding: '0px',
                                            fontWeight: 'normal',
                                            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                                            color: 'black'
                                        }}>
                                            New Game In: {Math.floor(remainingTime / 60)}:{remainingTime % 60}
                                        </p>
                                        : null}
                                    {/* <button

                                        style={{
                                            margin: '10px',
                                        }}
                                        className="play-button"
                                        onClick={() => handleGameSelect(game)}
                                    >
                                        PLAY NOW
                                    </button> */}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="navigation-buttons" style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
                    <button className="nav-button back" onClick={handleBack}>Account</button>
                    <button className="nav-button exit"
                        onClick={handleExit}
                        style={{ backgroundColor: 'red', color: 'white' }}>Logout
                    </button>
                </div>
            </div>
            <FooterLinks />
        </div>
    );
}

export default WelcomeScreen;
