import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function RegisterScreen({ onRegistrationSuccess }) {
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number is not valid').required('Mobile number is required'),
            otp: otpSent ? Yup.string().required('OTP is required') : Yup.string(),
        }),
        onSubmit: (values) => {
            console.log('Form submitted', values);

            if (otpSent) {
                console.log('Verifying OTP...');

                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("mobile", values.mobile);
                urlencoded.append("otp", otp);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://sikandar.in/api/verify-otp", requestOptions)
                    .then((response) => response.json()) // Parse the JSON response
                    .then((result) => {
                        console.log(result);

                        if (result.message === "OTP verified. Login successful.") {
                            // Store user details in localStorage
                            localStorage.setItem('isLoggedIn', 'true');
                            localStorage.setItem('userId', result.user._id);
                            localStorage.setItem('userName', result.user.name);
                            localStorage.setItem('userMobile', result.user.mobile);

                            // Navigate to /welcome and remove the current screen from history
                            navigate('/welcome', { replace: true });
                        } else {
                            alert('Invalid or expired OTP, Please try again!');
                        }
                    })
                    .catch((error) => console.error('Error verifying OTP:', error));
            } else {
                console.log('Sending OTP...');

                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("name", values.name);
                urlencoded.append("mobile", values.mobile);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch("https://sikandar.in/api/register", requestOptions)
                    .then((response) => response.status)
                    .then((result) => {
                        console.log('OTP verification result:', result);
                        if (result === 201) {
                            console.log('OTP sent result:', result);
                            setOtpSent(true);
                        } else {
                            alert('Please check your details! If you\'re account is already registered, kindly login!');
                        }
                    })
                    .catch((error) => console.error('Error verifying OTP:', error));
            }
        },
    });
    const handleSubmit = (event) => {
        event.preventDefault();
        formik.handleSubmit();
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="registration-form">
                <h2 className="switch-screen-text" style={{ color: 'black', fontWeight: 'normal', fontSize: '20px' }}>Create a new account!</h2>
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>
                        {formik.errors.name}
                    </div>
                ) : null}

                <input
                    type="text"
                    name="mobile"
                    placeholder="Mobile Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                    <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>
                        {formik.errors.mobile}
                    </div>
                ) : null}

                {otpSent && (
                    <input
                        type="text"
                        name="otp"
                        placeholder="Enter OTP"
                        onChange={(e) => {
                            setOtp(e.target.value);
                            formik.setFieldValue('otp', e.target.value);
                        }}
                        value={formik.values.otp}
                    />
                )}
                {formik.touched.otp && formik.errors.otp ? <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '20px' }}>{formik.errors.otp}</div> : null}
                <button type="submit" className="play-button-login">
                    {otpSent ? 'Verify OTP' : 'Send OTP'}
                </button>
            </form>
        </div>
    );
}

export default RegisterScreen;