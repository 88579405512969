import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const DepositScreen = () => {
    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
            <h5 style={{ fontSize: '24px', margin: '10px' }} className='footer-links-row'>Deposit Process: How It Works</h5>
            <p className='footer-links-row' style={{ fontSize: '18px' }} >
                Welcome to the deposit section of our Sikandar game platform! Here's a quick guide to help you understand how to deposit funds into your wallet.
                1. Choose Your Payment Method
                On the deposit page, you’ll see three payment options:
                o UPI ID
                o QR Scanner
                o Account Number
                2. Make Your Payment
                Choose your preferred method, make the payment, and keep a screenshot of the transaction.
                3. Upload Your Screenshot
                After completing the payment, upload the transaction screenshot on the deposit page. This helps us verify your payment.
                4. Verification Process
                Once you’ve uploaded the screenshot, our team will review and verify the transaction. This process may take up to 24 hours. If everything checks out, the deposit will reflect in your wallet.
                5. Need Assistance?
                If your deposit has not been reflected in your wallet after 24 hours, don’t worry! Simply contact us at gamesikandar@gmail.com and we’ll assist you right away.
                Thank you for your patience and understanding!
            </p>
        </div>
    </div>
    );
};

export default DepositScreen;
