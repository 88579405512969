import { Button, Box, Typography, Container, AppBar, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, CssBaseline } from '@mui/material';
import { Menu, Home as HomeIcon, Dashboard, People, MonetizationOn, History, Logout } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import UserList from './UserList';
import TransactionList from './TransactionList';
import DashboardManagement from './DashboardManagement';
import DepositList from './DepositList';
import WithdrawalList from './WithdrawalList';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import React, { useState, useEffect } from 'react';
import { Table, Badge, Card, Row, Col, CardGroup } from 'react-bootstrap';
import { FaUsers, FaMoneyBillWave, FaChartLine, FaDice, FaWallet } from 'react-icons/fa';
import { FaGamepad, FaRegChartBar, FaUser, FaClipboardCheck } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChangePassword from './ChangePassword';
import LockClockIcon from '@mui/icons-material/LockClock';
import TotalZodiacGame from './TotalZodiacGame';
import TotalBetDetails from './TotalBetDetails';
import UserBetDetails from './UserBetDetails';
import GameParticipationResults from './GameParticipationResults';
import TotalNumberGame from './TotalNumberGame';
import TotalColorGame from './TotalColorGame';

const drawerWidth = 240;

const Home = ({ onLogout }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [screen, setScreen] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [activeUsers, setActiveUsers] = useState(0);
  const [newRegistrations, setNewRegistrations] = useState(0);
  const [totalBets, setTotalBets] = useState(0);
  const [totalGamesToday, setTotalGamesToday] = useState(0);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);

  // Fetch user statistics from the API
  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await fetch('https://sikandar.in/users/stats'); // Replace with actual API URL
        const data = await response.json();

        // Update the state with the API data
        setActiveUsers(data.totalUsers);
        setNewRegistrations(data.usersLastTenDays);
        setTotalBets(data.totalBets);
        setTotalGamesToday(data.totalGamesToday);
        setTotalDeposits(data.totalDeposits);
        setTotalWithdrawals(data.totalWithdrawals);
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchUserStats();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* Header */}
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <Menu />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}>
        <Toolbar />
        <div>
          <List>
            <ListItem button component={Link} onClick={() => {
              setScreen(0);
            }}>
              <ListItemIcon ><Dashboard /></ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} onClick={() => {
              setScreen(1);
            }}>
              <ListItemIcon><People /></ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
            <ListItem button component={Link} onClick={() => {
              setScreen(2);
            }}>
              <ListItemIcon><AddShoppingCartIcon /></ListItemIcon>
              <ListItemText primary="Deposits" />
            </ListItem>
            <ListItem button component={Link} onClick={() => {
              setScreen(3);
            }}>
              <ListItemIcon><ShoppingCartCheckoutIcon /></ListItemIcon>
              <ListItemText primary="Withdrawals" />
            </ListItem>
            <ListItem button component={Link} onClick={() => setScreen(5)}>
              <ListItemIcon><FaGamepad /></ListItemIcon>
              <ListItemText primary="Total Zodiac Game" />
            </ListItem>
            <ListItem button component={Link} onClick={() => setScreen(6)}>
              <ListItemIcon><FaGamepad /></ListItemIcon>
              <ListItemText primary="Total Number Game" />
            </ListItem>
            <ListItem button component={Link} onClick={() => setScreen(7)}>
              <ListItemIcon><FaGamepad /></ListItemIcon>
              <ListItemText primary="Total Color Game" />
            </ListItem>
            <ListItem button component={Link} onClick={() => {
              setScreen(4);
            }}>
              <ListItemIcon><LockClockIcon /></ListItemIcon>
              <ListItemText primary="Change Password" />
            </ListItem>
            {/* <ListItem button component={Link} onClick={() => {

            }}>
              <ListItemIcon><History /></ListItemIcon>
              <ListItemText primary="Game History" />
            </ListItem> */}
            <ListItem button component={Link} onClick={onLogout}>
              <ListItemIcon><Logout /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Container component="main">
          {screen == 0 ? <Container fluid className="py-4 w-100">
            <h2 className="text-center mb-4">Admin Dashboard</h2>

            {/* User Statistics */}
            <Row className="mb-4">
              <CardGroup onClick={() => { setScreen(1); }} className="w-100"><Col xs={12} sm={6} md={6} style={{ padding: '10px' }}>
                <Card className="text-center shadow-sm mb-3">
                  <Card.Body>
                    <FaUsers size={40} className="mb-2 text-primary" />
                    <Card.Title>Active Users</Card.Title>
                    <Card.Text style={{ fontSize: '40px' }}>
                      <Badge pill bg="primary">{activeUsers}</Badge>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col><Col xs={12} sm={6} md={6} style={{ padding: '10px' }}>
                  <Card className="text-center shadow-sm mb-3">
                    <Card.Body>
                      <FaChartLine size={40} className="mb-2 text-success" />
                      <Card.Title>New Registrations (Last 10 Days)</Card.Title>
                      <Card.Text style={{ fontSize: '40px' }}>
                        <Badge pill bg="success">{newRegistrations}</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </CardGroup>
            </Row>

            {/* Financial Reports */}
            <Row className="mb-4">
              <CardGroup className="w-100">
                <Col xs={12} sm={6} md={6} style={{ padding: '10px' }}>
                  <Card className="text-center shadow-sm mb-3">
                    <Card.Body>
                      <FaMoneyBillWave size={40} className="mb-2 text-primary" />
                      <Card.Title>Total Bets Today</Card.Title>
                      <Card.Text style={{ fontSize: '40px' }}>
                        <Badge pill bg="primary">₹{totalBets}</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col><Col xs={12} sm={6} md={6} style={{ padding: '10px' }}>
                  <Card className="text-center shadow-sm mb-3">
                    <Card.Body>
                      <FaDice size={40} className="mb-2 text-info" />
                      <Card.Title>Total Games Today</Card.Title>
                      <Card.Text style={{ fontSize: '40px' }}>
                        <Badge pill bg="info">{totalGamesToday}</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </CardGroup>
            </Row>

            {/* Deposits and Withdrawals */}
            <Row className="mb-4">
              <CardGroup className="w-100">
                <Col onClick={() => { setScreen(2); }} xs={12} sm={6} md={6} style={{ padding: '10px' }}>
                  <Card className="text-center shadow-sm mb-3">
                    <Card.Body>
                      <FaWallet size={40} className="mb-2 text-primary" />
                      <Card.Title>Total Deposits Today</Card.Title>
                      <Card.Text style={{ fontSize: '40px' }}>
                        <Badge pill bg="primary">₹{totalDeposits}</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col onClick={() => { setScreen(3); }} xs={12} sm={6} md={6} style={{ padding: '10px' }}>
                  <Card className="text-center shadow-sm mb-3">
                    <Card.Body>
                      <FaWallet size={40} className="mb-2 text-danger" />
                      <Card.Title>Total Withdrawals Today</Card.Title>
                      <Card.Text style={{ fontSize: '40px' }}>
                        <Badge pill bg="danger">₹{totalWithdrawals}</Badge>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </CardGroup>
            </Row>
          </Container> : null}
          {screen == 1 ? <UserList /> : null}
          {screen == 2 ? <DepositList /> : null}
          {screen == 3 ? <WithdrawalList /> : null}
          {screen == 4 ? <ChangePassword /> : null}
          {screen == 5 ? <TotalZodiacGame /> : null}
          {screen == 6 ? <TotalNumberGame /> : null}
          {screen == 7 ? <TotalColorGame /> : null}
          {screen == 8 ? <GameParticipationResults /> : null}
        </Container>
      </Box>
    </Box>
  );
};

export default Home;