import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AccountScreen.css';
import './LandingPage.css';
import logo from '../assets/colored_logo.png';
import WalletAmountPopup from './WalletAmountPopup';
import WithdrawPopup from './WithdrawPopup';

function AccountScreen() {
    const [balance, setBalance] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState('');
    const [transactionHistory, setTransactionHistory] = useState([]);
    const navigate = useNavigate();

    const fetchUserBalance = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch(`https://sikandar.in/users/${localStorage.getItem('userId')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setBalance(result.balance);
            })
            .catch(error => console.error('Error fetching user data:', error));
    };

    const fetchTransactionHistory = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch(`https://sikandar.in/api/transaction-history/${localStorage.getItem('userId')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('Here');

                setTransactionHistory(result); // Update to match your API response format
            })
            .catch(error => console.error('Error fetching transaction history:', error));
    };

    useEffect(() => {
        fetchUserBalance();
        fetchTransactionHistory();
    }, []);

    const handleDeposit = () => {
        setPopupType('deposit');
        setShowPopup(true);
    };

    const handleWithdraw = () => {
        setPopupType('withdraw');
        setShowPopup(true);
    };

    const handlePopupSubmit = (amount) => {
        if (amount > 0) {
            if (popupType === 'deposit') {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                const urlencoded = new URLSearchParams();
                urlencoded.append("amount", amount.toString());

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                fetch(`https://sikandar.in/api/deposit/${localStorage.getItem('userId')}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        console.log(result);
                        setBalance(balance + amount);
                        alert(`Deposited ₹${amount} successfully.`);
                    })
                    .catch(error => console.error('Error:', error));
            } else if (popupType === 'withdraw' && amount <= balance) {
                setBalance(balance - amount);
                alert(`Withdrew ₹${amount} successfully.`);
            } else {
                alert("Insufficient balance for withdrawal.");
            }
        } else {
            alert("Invalid amount.");
        }
        setShowPopup(false);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    const handleBackToHome = () => {
        navigate(-1);
    };

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
            <div className="responsive-div" style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', backgroundColor: 'white' }}>
                <img style={{ margin: '10px', padding: '0px', width: '150px' }} src={logo} alt="Sikandar Logo" />
                <div className="account-header">
                    <p style={{ fontSize: '24px', margin: '10px', padding: '0px', fontWeight: '500', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", color: 'black' }}>Account Dashboard</p>
                </div>
                <div className="account-info">
                    <p style={{ fontSize: '24px', margin: '10px', padding: '0px', fontWeight: '500', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", color: 'black' }}>Account Info</p>
                    <p style={{ fontSize: '18px', margin: '10px', padding: '0px', fontWeight: '500', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", color: 'black' }}>User Name: {localStorage.getItem('userName')}</p>
                    <p style={{ fontSize: '18px', margin: '10px', padding: '0px', fontWeight: '500', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", color: 'black' }}>User Mobile: {localStorage.getItem('userMobile')}</p>
                    <p style={{ fontSize: '22px', margin: '10px', padding: '0px', fontWeight: '500', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", color: 'black' }}>Wallet Balance: ₹{balance}</p>
                </div>

                <div className="account-actions">
                    <button className="play-button" style={{ margin: '0px', marginBottom: '10px' }} onClick={handleDeposit}>Deposit</button>
                    <div style={{ width: '10px' }} />
                    <button className="play-button" style={{ margin: '0px', marginBottom: '10px' }} onClick={handleWithdraw}>Withdraw</button>
                    <div style={{ width: '10px' }} />
                </div>

                <div className="transaction-history" style={{ width: '100%', fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif", }}>
                    <p style={{ fontSize: '24px', margin: '10px', padding: '0px', fontWeight: '500', color: 'black' }}>User History</p>
                    <table style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    }}>
                        <thead>
                            <tr style={{ backgroundColor: '#007BFF', color: 'white' }}>
                                <th style={{ border: '1px solid gray', padding: '10px', textAlign: 'left' }}>Transaction Type</th>
                                <th style={{ border: '1px solid gray', padding: '10px', textAlign: 'left' }}>Amount (₹)</th>
                                <th style={{ border: '1px solid gray', padding: '10px', textAlign: 'left' }}>Date</th>
                                <th style={{ border: '1px solid gray', padding: '10px', textAlign: 'left' }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionHistory.length === 0 ? (
                                <tr>
                                    <td colSpan="4" style={{ textAlign: 'center', padding: '10px' }}>No history available.</td>
                                </tr>
                            ) : (
                                transactionHistory.map((transaction, index) => (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
                                        <td style={{ border: '1px solid gray', padding: '10px' }}>
                                            {transaction.transactionType.charAt(0).toUpperCase() + transaction.transactionType.slice(1)}
                                        </td>
                                        <td style={{ border: '1px solid gray', padding: '10px' }}>{transaction.amount}</td>
                                        <td style={{ border: '1px solid gray', padding: '10px' }}>{new Date(transaction.timestamp).toLocaleString()}</td>
                                        <td style={{ border: '1px solid gray', padding: '10px' }}>
                                            <span style={{
                                                color: transaction.status === 'approved' ? 'green' :
                                                    transaction.status === 'pending' ? 'orange' : 'red'
                                            }}>
                                                {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)}
                                            </span>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <button className="play-button" style={{ margin: '10px', backgroundColor: 'blue', color: 'white' }} onClick={handleBackToHome}>GO BACK</button>

            {showPopup && popupType === 'deposit' ?
                <WalletAmountPopup
                    onClose={handlePopupClose}
                    onSubmit={() => {
                        setShowPopup(false);
                        fetchTransactionHistory();
                    }}
                />
                : null}
            {showPopup && popupType === 'withdraw' ? <WithdrawPopup
                onClose={handlePopupClose}
                onSubmit={() => {
                    setShowPopup(false);
                    fetchTransactionHistory();
                }} /> : null}
        </div>
    );
}

export default AccountScreen;