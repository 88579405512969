import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const AboutUsScreen = () => {
    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
            <h5 style={{ fontSize: '24px', margin: '10px' }} className='footer-links-row'>About Us</h5>
            <p className='footer-links-row' style={{ fontSize: '18px', textAlign: 'center' }} >
                Welcome to Sikandar Game, where your thrill meets endless possibilities! We are a premier betting gaming platform offering an exhilarating experience through our three unique games: Colour Game, Number Game, and Rashi Game. Our mission is to provide a fair, secure, and entertaining environment for all players, giving you the chance to multiply your winnings by nine times! At Sikandar Game, we prioritize your gaming pleasure and strive to create a community where everyone can enjoy and win big. Join us today and be a part of the excitement!
            </p>
        </div>
    </div>
    );
};

export default AboutUsScreen;
