import React from 'react';
import './LandingPage.css';
import { useNavigate } from 'react-router-dom';
import FooterLinks from './FooterLinks';

function LandingScreen({ onScreenChange }) {

    const navigate = useNavigate();

    const goToAuth = () => {
        navigate('/auth');
    };

    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />

            <div className="landing-content">
                <h3 className="game-title">WELCOME TO SIKANDAR</h3>
                <p className="tagline">WORLD OF THE GAME</p>
                <div className="game-options" style={{ flexDirection: 'column', display: 'flex' }}>
                    <div className="game-option orange">
                        <h4>RASHI GAME</h4>
                    </div>
                    <div className="game-option white">
                        <h4>COLOUR GAME</h4>
                    </div>
                    <div className="game-option green">
                        <h4>NUMBER GAME</h4>
                    </div>
                </div>
                <button className="play-button animated" onClick={goToAuth}>
                    Play Now
                </button>
            </div>
        </div>
        <FooterLinks />
    </div>
    );
}

export default LandingScreen;