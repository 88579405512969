import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

const GameParticipationResults = () => {
    const [participationDetails, setParticipationDetails] = useState([]);

    useEffect(() => {
        const fetchParticipationDetails = async () => {
            try {
                const response = await fetch('https://sikandar.in/api/games/participation');
                const data = await response.json();
                setParticipationDetails(data);
            } catch (error) {
                console.error('Error fetching participation details:', error);
            }
        };

        fetchParticipationDetails();
    }, []);

    return (
        <div>
            <h2>Game Participation and Results</h2>
            <Table striped bordered hover style={{ overflow: 'scroll' }}>
                <thead>
                    <tr>
                        <th>Game ID</th>
                        <th>Game</th>
                        <th>Timing</th>
                        <th>Users Participated</th>
                        <th>Winner</th>
                        <th>Highest Bet Amount</th>
                        <th>Lowest Bet Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {participationDetails.map((participation) => (
                        <tr key={participation.gameId}>
                            <td>{participation.gameId}</td>
                            <td>{participation.gameType}</td>
                            <td>{new Date(participation.timing).toLocaleString()}</td>
                            <td>{participation.usersParticipated}</td>
                            <td>{participation.colorWon}</td>
                            <td>₹{participation.highestBetAmount}</td>
                            <td>₹{participation.lowestBetAmount}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default GameParticipationResults;