import React, { useState } from 'react';

const WithdrawPopup = ({ onClose, onSubmit }) => {
    const [amount, setAmount] = useState('');
    const [upiId, setUpiId] = useState('');
    const [bankDetails, setBankDetails] = useState({
        accountHolderName: '',
        accountNumber: '',
        ifscCode: ''
    });
    const [useUPI, setUseUPI] = useState(true);
    const [loading, setLoading] = useState(false); // State to track loading
    const [error, setError] = useState(null); // State to track errors

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBankDetails(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async () => {
        if (!amount || isNaN(amount)) {
            alert('Please enter a valid amount.');
            return;
        }

        const data = {
            amount,
            ...(useUPI ? { upiId } : { bankDetails })
        };

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`https://sikandar.in/api/withdraw/${localStorage.getItem('userId')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (response.ok) {
                alert('Withdrawal request submitted!');
                onSubmit(result.transaction);
            } else {
                setError(result.message || 'Error submitting request');
            }
        } catch (error) {
            
            setError('An error occurred during submission.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
            width: '350px',
            maxHeight: '80vh',
            overflowY: 'auto',
            textAlign: 'center'
        }}>
            <h3 style={{ margin: '0 0 15px', fontSize: '18px', color: '#333' }}>Withdraw Funds</h3>

            <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px' }}>
                Amount:
                <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    style={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        marginBottom: '15px',
                        fontSize: '16px'
                    }}
                />
            </label>

            <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-around' }}>
                <label style={{ marginRight: '15px' }}>
                    <input
                        type="radio"
                        checked={useUPI}
                        onChange={() => setUseUPI(true)}
                    />
                    UPI
                </label>
                <label>
                    <input
                        type="radio"
                        checked={!useUPI}
                        onChange={() => setUseUPI(false)}
                    />
                    Bank Transfer
                </label>
            </div>
            {useUPI ? (
                <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px' }}>
                    UPI ID:
                    <input
                        type="text"
                        value={upiId}
                        onChange={(e) => setUpiId(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ddd',
                            marginBottom: '15px',
                            fontSize: '16px'
                        }}
                    />
                </label>
            ) : (
                <div>
                    <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px' }}>
                        Account Holder Name:
                        <input
                            type="text"
                            name="accountHolderName"
                            value={bankDetails.accountHolderName}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ddd',
                                marginBottom: '15px',
                                fontSize: '16px'
                            }}
                        />
                    </label>
                    <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px' }}>
                        Account Number:
                        <input
                            type="text"
                            name="accountNumber"
                            value={bankDetails.accountNumber}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ddd',
                                marginBottom: '15px',
                                fontSize: '16px'
                            }}
                        />
                    </label>
                    <label style={{ fontSize: '16px', color: '#333', marginBottom: '5px' }}>
                        IFSC Code:
                        <input
                            type="text"
                            name="ifscCode"
                            value={bankDetails.ifscCode}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '5px',
                                border: '1px solid #ddd',
                                marginBottom: '15px',
                                fontSize: '16px'
                            }}
                        />
                    </label>
                </div>
            )}

            {error && (
                <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            )}

            <button
                onClick={handleSubmit}
                style={{
                    margin: '5px',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    backgroundColor: '#28a745',
                    color: '#fff',
                    fontSize: '16px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease'
                }}
                disabled={loading}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#218838'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#28a745'}
            >
                {loading ? 'Submitting...' : 'Submit Withdrawal'}
            </button>

            <button
                onClick={onClose}
                style={{
                    margin: '5px',
                    padding: '10px 20px',
                    border: 'none',
                    borderRadius: '5px',
                    backgroundColor: '#dc3545',
                    color: '#fff',
                    fontSize: '16px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease'
                }}
                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#c82333'}
                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#dc3545'}
            >
                Cancel
            </button>
        </div>
    );
};

export default WithdrawPopup;