import React, { useState, useEffect } from 'react';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import './AuthContainer.css';
import { useNavigate } from 'react-router-dom';

function AuthContainer({ onScreenChange }) {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLogin, setShowLogin] = useState(true);

    // Load registration and login states from localStorage when the component mounts
    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (loggedIn) {
            navigate('/welcome', { replace: true });
        }
    }, []);

    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
            <div className="landing-content">
                <h3 className="game-title">WELCOME TO SIKANDAR</h3>
                <p className="tagline">WORLD OF THE GAME</p>
                {showLogin ? (
                    <div className="auth-content">
                        <LoginScreen onLoginSuccess={() => {

                        }} />
                        <p className="switch-screen-text">
                            Don't have an account?{' '}
                            <span
                                className="switch-screen-text"
                                style={{ color: 'blue', fontWeight: 'normal', fontSize: '20px', cursor: 'pointer' }}
                                onClick={() => setShowLogin(false)}
                            >
                                Register Here!
                            </span>
                        </p>

                    </div>
                ) : (
                    <div className="auth-content">
                        <RegisterScreen onRegistrationSuccess={() => {

                        }} />
                        <p className="switch-screen-text">
                            Already have an account?{' '}
                            <span
                                className="switch-screen-text"
                                style={{ color: 'blue', fontWeight: 'normal', fontSize: '20px', cursor: 'pointer' }}
                                onClick={() => setShowLogin(true)}
                            >
                                Login Here
                            </span>
                        </p>
                    </div>
                )}
            </div>
        </div>
    </div>
    );
}

export default AuthContainer;