import React, { useState, useEffect } from 'react';
import './NumberScreen.css';
import profile from '../assets/coin.png'; // Path to your logo image
import { useNavigate } from 'react-router-dom';
import AmountPopup from './AmountPopup';
import { Alert } from 'react-bootstrap'; // Import Alert component

const NUMBERS = Array.from({ length: 10 }, (_, i) => i);

function NumberScreen({ onScreenChange }) {
    const navigate = useNavigate(); // Hook for navigation
    const [selectedNumber, setSelectedNumber] = useState(null);
    const [betAmount, setBetAmount] = useState(0);
    const [betPlaced, setBetPlaced] = useState(false);
    const [gameID, setGameID] = useState('');
    const [timeLeft, setTimeLeft] = useState(10); // 10 minutes in seconds
    const [winningNumber, setWinningNumber] = useState(null);
    const [showNotification, setShowNotification] = useState(false); // Notification state
    const [winHistory, setWinHistory] = useState([]); // State to track win history
    const [chosenNumbers, setChosenNumbers] = useState([]);
    const [chosenItems, setChosenItems] = useState([]);
    const [endTime, setEndTime] = useState(null);
    const [balance, setBalance] = useState(null);
    const [totalBetAmount, setTotalBetAmount] = useState(0);
    const [showNoMoreBets, setShowNoMoreBets] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertWinner, setShowAlertWinner] = useState(false);
    const [showAlertBalance, setShowAlertBalance] = useState(false);
    const [winner, setWinner] = useState(null);
    const [winnerAlert, setWinnerAlert] = useState('');

    useEffect(() => {
        let timer;
        if (showAlert) {
            // Set a timer to hide the alert after 5 seconds
            timer = setTimeout(() => setShowAlert(false), 5000);

        }
        // Cleanup timer on component unmount or when showAlert changes
        return () => clearTimeout(timer);
    }, [showAlert]);

    useEffect(() => {
        let timer;
        if (showAlertWinner) {
            // Set a timer to hide the alert and reload the window after 5 seconds
            timer = setTimeout(() => {
                setShowAlertWinner(false);
                window.location.reload();
            }, 5000);
        }
        // Cleanup timer on component unmount or when showAlert changes
        return () => clearTimeout(timer);
    }, [showAlertWinner]);


    useEffect(() => {
        let timer;
        if (showAlertBalance) {
            timer = setTimeout(() => setShowAlertBalance(false), 5000);
        }
        // Cleanup timer on component unmount or when showAlert changes
        return () => clearTimeout(timer);
    }, [showAlertBalance]);

    const handlePopupClose = () => {
        setPopupVisible(false); // Hide the popup
    };

    const handleAmountSubmit = (amount) => {
        console.log('Selected Amount:', amount); // Handle the amount submission
        console.log('Selected Number:', selectedNumber); // Handle the number submission

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("chosen", selectedNumber); // Use selectedNumber
        urlencoded.append("betAmount", amount.toString()); // Convert amount to string

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch(`https://sikandar.in/api/place-bet/${localStorage.getItem('userId')}/${gameID}`, requestOptions)
            .then(response => {
                if (response.status === 400) {
                    setShowAlertBalance(true);
                    return response.text(); // Still handle response text to avoid issues
                } else if (response.ok) {
                    return response.text();
                } else {
                    throw new Error('Unexpected response status');
                }
            })
            .then(result => {
                setPopupVisible(false); // Hide the popup after submission
                fetchBetAmount(gameID);
                fetchChosenNumbers(gameID);
                fetchUserBalance();
                setShowAlert(true);
            })
            .catch(error => console.error('Error placing bet:', error));
    };


    const fetchBetAmount = async (gameID) => {
        //     try {
        //         const response = await fetch(`https://sikandar.in/api/bets/total/${gameID}`);
        //         const data = await response.json();
        //         setTotalBetAmount(data.totalBetAmount);
        //     } catch (error) {
        //         console.error('Error fetching total bet amount:', error);
        //     }
    };

    const fetchChosenNumbers = async (gameID) => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        try {
            const response = await fetch(`https://sikandar.in/bets/${localStorage.getItem('userId')}/${gameID}`, requestOptions);
            const result = await response.json();

            // Set the chosen items
            setChosenItems(result);

            // Extract and reverse the chosen numbers
            const numbers = result.map(bet => bet.chosen);
            setChosenNumbers(numbers.reverse());

            // Calculate the total betAmount
            const totalBetAmount = result.reduce((sum, bet) => sum + bet.betAmount, 0);
            setTotalBetAmount(totalBetAmount);
        } catch (error) {
            console.error("Error fetching bets:", error);
        }
    };

    const fetchUserBalance = async () => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch(`https://sikandar.in/users/${localStorage.getItem('userId')}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // Extract balance from the response and update state
                setBalance(result.balance);
            })
            .catch(error => console.error('Error fetching user data:', error));
    }


    useEffect(() => {
        const fetchAndUpdateGameData = async () => {
            try {
                const response = await fetch('https://sikandar.in/api/games/active');
                const data = await response.json();
                const numberGame = data.find(game => game.gameType === 'Number');
                if (numberGame) {
                    const endTime = new Date(numberGame.endTime);
                    endTime.setSeconds(endTime.getSeconds() + 5);
                    fetchUserBalance();
                    fetchBetAmount(numberGame._id);
                    fetchChosenNumbers(numberGame._id);
                    setGameID(numberGame._id);
                    setEndTime(endTime);

                    // Update the timer initially
                    const updateTimer = () => {
                        const now = new Date();
                        const timeDifference = endTime - now;
                        console.log(Math.ceil((timeDifference / 1000)));

                        if (Math.ceil((timeDifference / 1000)) > 5) {
                            setTimeLeft(Math.ceil((timeDifference / 1000))); // Convert milliseconds to seconds
                        }
                        else {
                            setTimeLeft(Math.ceil((timeDifference / 1000))); // Convert milliseconds to seconds
                            const requestOptions = {
                                method: "GET",
                                redirect: "follow"
                            };
                            fetch("https://sikandar.in/api/game-history/Number", requestOptions)
                                .then(response => response.json())
                                .then(data => {
                                    // Find the game with the specified ID
                                    const game = data.find(game => game._id === numberGame._id);

                                    if (game) {
                                        let winningItem = '';
                                        setChosenItems((chosenItems) => {
                                            winningItem = chosenItems.find(item => item.chosen === game.winning);
                                            if (winningItem) {
                                                setWinnerAlert(`You have won. Your amount is ₹${winningItem.betAmount * 9}!`);
                                                setShowAlertWinner(true);
                                            }
                                            else {
                                                setWinnerAlert(`Better luck next time!`);
                                                setShowAlertWinner(true);
                                            }
                                            return chosenItems;
                                        });
                                        setWinner(game.winning);

                                    } else {
                                        console.error('Game not found');
                                        return null;
                                    }
                                })
                                .catch(error => console.error('Error fetching game history:', error));
                            // } else {
                            // fetchAndUpdateGameData();
                            // setTimeLeft(0);
                            // const randomNumber = NUMBERS[Math.floor(Math.random() * NUMBERS.length)];
                            // setWinningNumber(randomNumber);
                            // setShowNotification(true);
                            // setTimeout(() => {
                            //     setShowNotification(false);
                            //     fetchAndUpdateGameData(); // Fetch new game data after restart
                            // }, 5000);
                        }
                    };

                    // Initial call and set interval for updating the timer
                    const timer = setInterval(updateTimer, 1000);
                    updateTimer(); // Initial call to set the timer immediately
                    return () => clearInterval(timer);
                }
            } catch (error) {
                console.error('Error fetching game data:', error);
            }
        };

        fetchAndUpdateGameData();
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };
        fetch("https://sikandar.in/api/game-history/Number", requestOptions)
            .then(response => response.json())
            .then(data => setWinHistory(data.map(game => game.winning)))
            .catch(error => console.error('Error fetching game history:', error));
    }, []);

    const handleNumberSelect = (number) => {
        if (!betPlaced) {
            // onSelect(number);
            setSelectedNumber(number);
            setPopupVisible(true); // Show the popup when a number is selected
        }
    };

    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />

            <div style={{
                alignItems: 'space-evenly',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                backgroundColor: '#F6F6F6',
                padding: '0 10px', padding: '0px'
            }}>
                <div style={{ width: '10px' }} />
                <div style={{
                    alignItems: 'start',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <img style={{ margin: '10px', padding: '0px', width: '20px', height: '20px' }} src={profile} alt="Sikandar Logo" />
                        <div style={{
                            alignItems: 'start',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            Amount: ₹{balance !== null ? balance : 'Loading...'}
                            <p
                                onClick={() => {
                                    navigate('/account');
                                }}
                                style={{
                                    margin: 0,
                                    fontWeight: 'bold',
                                    color: 'blue',
                                    fontSize: '12px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'  // This adds a pointer cursor to indicate it's clickable
                                }}
                            >
                                My Account
                            </p>
                        </div>
                    </p>
                    <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1.2em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        Winner: {winner}
                    </p>
                </div>
                <div style={{ flex: 1 }} />
                <div style={{
                    alignItems: 'end',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}>
                    <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        Bet Amount: ₹{totalBetAmount}
                    </p>
                    <Timer endTime={endTime} />
                </div>
                <div style={{ width: '10px' }} />
            </div>
            <div style={{ height: '10px' }} />
            <NumberSelector
                numbers={NUMBERS}
                selectedNumber={selectedNumber}
                onSelect={handleNumberSelect}
                disabled={timeLeft < 60}
                chosenNumbers={chosenNumbers.map(Number)}
            />
            {popupVisible && (
                <AmountPopup
                    onClose={handlePopupClose}
                    onSubmit={handleAmountSubmit}
                />
            )}
            <div style={{
                border: '2px solid blue',
                padding: '10px',
                margin: '10px',
                borderRadius: '10px',
                textAlign: 'left'
            }}>
                <p style={{ margin: '0 0 0 0', padding: '0px', fontWeight: 'bolder' }}>Number Representation</p>
                <p style={{ margin: '0', padding: '0px', fontSize: '24px', fontWeight: 'bold', color: 'maroon' }}>
                    {chosenNumbers.join(', ')}
                </p>
                <WinHistory winHistory={winHistory} />
            </div>
            {/* <BettingPanel
                onBet={handleBet}
                disabled={selectedNumber === null || betPlaced}
                amountDisabled={betPlaced}
            />
            {betPlaced && <p className="confirmation-message">Your bet has been placed!</p>} */}

            <div style={{ width: '100%', padding: '0 10px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <button
                    onClick={() => {
                        navigate('/zodiac');
                    }}
                    style={{
                        borderRadius: '5px',
                        padding: '10px 50px',
                        backgroundColor: 'orange', // Use 'lightblue' for pale blue
                        color: 'white', // Ensure text is visible on the pale blue background
                        border: 'none', // Optional: remove border for a cleaner look
                    }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Rashi Game</p>
                </button>
                <button
                    onClick={() => {
                        navigate('/color');
                    }}
                    style={{
                        borderRadius: '5px',
                        padding: '10px 50px',

                        backgroundColor: 'white',
                        color: 'black', // Ensure text is visible on the grey background
                        border: 'none', // Optional: remove border for a cleaner look
                    }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Color Game</p>
                </button>
            </div >
        </div >

        {showAlert && (
            <Alert
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                }}
            >
                Bet has been successfully placed!
            </Alert>
        )}
        {showAlertWinner && (
            <Alert
                variant="success"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                }}
            >{winnerAlert}</Alert>
        )}
        {showAlertBalance && (
            <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                    transition: 'opacity 0.5s ease-out',
                }}>You have insufficient balance. Kindly recharge your wallet.
            </Alert>
        )}
        {showNoMoreBets && (
            <Alert
                variant="danger"
                onClose={() => setShowAlert(false)}
                dismissible
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 1050,
                    transition: 'opacity 0.5s ease-out',
                }}>No more bets please!
            </Alert>
        )}
        {timeLeft <= 60 && timeLeft > 50 ? <Alert
            variant="danger"
            onClose={() => setShowAlert(false)}
            dismissible
            style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 1050,
                transition: 'opacity 0.5s ease-out',
            }}>No more bets please!
        </Alert> : null}
    </div>
    );
}

const NumberSelector = ({ numbers, selectedNumber, onSelect, disabled, chosenNumbers }) => {
    const firstColumn = numbers.slice(0, 5); // 0-4
    const secondColumn = numbers.slice(5, 10); // 5-9

    return (
        <div style={{ textAlign: 'center' }}>
            <h2 className="subtitle" style={{ marginBottom: '10px' }}>Select a Number:</h2>
            <div className="number-selector" style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
                {/* First Column */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {firstColumn.map((number) => (
                        <button
                            key={number}
                            onClick={() => onSelect(number)}
                            disabled={disabled || chosenNumbers.includes(number)}
                            className="number-button"
                            style={{
                                backgroundColor: 'blue',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 50px',
                                opacity: (disabled || chosenNumbers.includes(number)) ? 0.5 : 1,
                            }}>
                            {number}
                        </button>
                    ))}
                </div>

                {/* Second Column */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {secondColumn.map((number) => (
                        <button
                            key={number}
                            onClick={() => onSelect(number)}
                            disabled={disabled || chosenNumbers.includes(number)}
                            className="number-button"
                            style={{
                                backgroundColor: 'blue',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '5px 50px',
                                opacity: (disabled || chosenNumbers.includes(number)) ? 0.5 : 1,
                            }}>
                            {number}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

const BettingPanel = ({ onBet, disabled, amountDisabled }) => {
    const [amount, setAmount] = useState(0);

    const handleBet = () => {
        onBet(amount);
    };

    return (
        <div>
            <h2 className="subtitle">Place Your Bet:</h2>
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Number(e.target.value))}
                placeholder="Enter amount"
                disabled={amountDisabled}
                className="bet-input"
            />
            <button onClick={handleBet} disabled={disabled || amount <= 0} className="bet-button">
                Bet
            </button>
        </div>
    );
};

const Timer = ({ endTime }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Function to calculate time remaining in seconds
    function calculateTimeLeft() {
        const now = new Date().getTime();
        const timeDifference = Math.floor((endTime - now) / 1000); // Difference in seconds
        return timeDifference > 0 ? timeDifference : 0; // Ensure it's not negative
    }

    useEffect(() => {
        // Update the time left every second
        const timerInterval = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);

            if (newTimeLeft <= 0) {
                clearInterval(timerInterval); // Stop the timer when it reaches 0
            }
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(timerInterval);
    }, [endTime]);

    // Calculate minutes and seconds
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;

    return (
        <div style={{ margin: '0px', padding: '0px' }}>
            <p className="timer" style={{ margin: '0px', padding: '0px', fontSize: '1.2em', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                Time:
                <div style={{ color: 'blue', fontWeight: 'bold', marginLeft: '5px' }}>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </div>
            </p>
        </div>
    );
};

const Result = ({ selectedNumber, winningNumber, betAmount }) => {
    const isWin = selectedNumber === winningNumber;
    return (
        <div>
            <h2 className="result-title">Winning Number: {winningNumber}</h2>
            <h3 className="result-text">You {isWin ? 'Win' : 'Lose'}!</h3>
            {isWin && <p className="result-win">You won ${betAmount * 10}!</p>}
        </div>
    );
};

const WinHistory = ({ winHistory }) => {
    return (
        <div style={{ textAlign: 'left' }}>
            <p style={{ margin: '0 0 10px 0', padding: '0px', fontWeight: 'bolder' }}>Win History</p>
            <div className="win-history">
                {winHistory.length === 0 ? (
                    <p className="no-history">No history yet.</p>
                ) : (
                    winHistory.map((number, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '10px',
                                backgroundColor: 'lightgreen',
                                color: 'maroon',
                                padding: '5px',
                                margin: '5px',
                                borderRadius: '5px',
                                textAlign: 'center',
                            }}>
                            {number}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default NumberScreen;