import React, { useState, useEffect } from 'react';
import { Table, Button, Container, Row, Col, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DepositList = () => {
    const [deposits, setDeposits] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch deposits from the API
    const fetchDeposits = async () => {
        const response = await fetch('https://sikandar.in/api/deposits'); // Replace with actual API
        const data = await response.json();
        setDeposits(data.reverse());
    };

    useEffect(() => {
        fetchDeposits();
    }, []);

    // Filter based on search term
    const filteredDeposits = deposits.filter(deposit =>
        deposit.user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleApprove = async (id) => {
        const response = await fetch(`https://sikandar.in/api/deposit/${id}/approve`, {
            method: 'POST',
        });

        if (response.ok) {
            // Update local state
            const updatedDeposits = deposits.map(deposit =>
                deposit._id === id ? { ...deposit, status: 'approved' } : deposit
            );
            setDeposits(updatedDeposits);
            alert('Deposit approved successfully!');
        } else {
            const errorMsg = await response.text();
            alert(`Error approving deposit: ${errorMsg}`);
        }
    };

    const handleReject = async (id) => {
        const response = await fetch(`https://sikandar.in/api/deposit/${id}/reject`, {
            method: 'POST',
        });

        if (response.ok) {
            // Update local state
            const updatedDeposits = deposits.map(deposit =>
                deposit._id === id ? { ...deposit, status: 'rejected' } : deposit
            );
            setDeposits(updatedDeposits);
            alert('Deposit rejected successfully!');
        } else {
            const errorMsg = await response.text();
            alert(`Error rejecting deposit: ${errorMsg}`);
        }
    };

    const exportData = () => {
        // Implement CSV/Excel export functionality here
        console.log("Data exported as CSV/Excel");
    };

    return (
        <Container fluid style={{ width: '100%', height: '100vh' }} className="d-flex flex-column py-4">
            <h2 className="mb-4 text-center">Deposit Requests</h2>

            <Form className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search by name..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Form>

            <Row className="mb-5 flex-grow-1" style={{ overflow: 'scroll' }}>
                <Col>
                    <Table striped bordered hover responsive className="w-100">
                        <thead className="table-dark">
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Mobile</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Receipt</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredDeposits.map((deposit) => (
                                <tr key={deposit._id}>
                                    <td>{deposit._id}</td>
                                    <td>{deposit.user.name}</td>
                                    <td>{new Date(deposit.timestamp).toLocaleDateString()}</td>
                                    <td>{deposit.user.mobile}</td>
                                    <td>₹{deposit.amount}</td>
                                    <td>
                                        <span className={deposit.status === 'approved' ? 'text-success' : 'text-warning'}>
                                            {deposit.status.charAt(0).toUpperCase() + deposit.status.slice(1)}
                                        </span>
                                    </td>
                                    <td>
                                        {deposit.receipt && (
                                            <a href={'https://sikandar.in/' + deposit.receipt} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={'https://sikandar.in/' + deposit.receipt}
                                                    alt="Receipt"
                                                    style={{ width: '50px', height: 'auto', cursor: 'pointer' }}
                                                />
                                            </a>
                                        )}
                                    </td>
                                    <td>
                                        {deposit.status === 'pending' && (
                                            <>
                                                <Button variant="success" size="sm" onClick={() => handleApprove(deposit._id)}>
                                                    Approve
                                                </Button>
                                                <div style={{ width: '10px', height: '10px' }} />
                                                <Button variant="danger" size="sm" onClick={() => handleReject(deposit._id)} className="ml-2">
                                                    Reject
                                                </Button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Button variant="success" onClick={exportData}>
                Export Data (CSV/Excel)
            </Button>
        </Container>
    );
};

export default DepositList;