import React from 'react';
import './FooterLinks.css'; // Optional: For styling

const PrivacyPolicyScreen = () => {
    return (<div style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
    }}>
        <div className="responsive-div" style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
        }}>
            <img style={{ margin: '10px', padding: '0px', width: '150px', }} src={require('../assets/colored_logo.png')} alt="Sikandar Logo" />
            <h5 style={{ fontSize: '24px', margin: '10px' }} className='footer-links-row'>Privacy Policy</h5>
            <p className='footer-links-row' style={{ fontSize: '18px', textAlign: 'center' }} >
                Your privacy is important to us at Sikandar Game. We are committed to safeguarding your personal information and ensuring that your experience with us is secure. We collect and use your data solely to enhance your gaming experience and provide personalized services. We do not share your information with third parties without your consent, except as required by law. For any inquiries regarding your personal data, please contact us at [gamesikandar@gmail.com](mailto:gamesikandar@gmail.com).
            </p>
        </div>
    </div>
    );
};

export default PrivacyPolicyScreen;