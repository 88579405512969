import React, { useState } from 'react';
import logo from '../assets/barcode.jpeg'; // Path to your logo image

const WalletAmountPopup = ({ onClose, onSubmit, userId }) => {
    const [customAmount, setCustomAmount] = useState('');
    const [paymentReceipt, setPaymentReceipt] = useState(null); // State to store the uploaded payment receipt
    const [loading, setLoading] = useState(false); // State to track if the request is loading
    const [error, setError] = useState(null); // State to track if there's an error

    const predefinedAmounts = [100, 200, 500, 1000];

    const handleAmountClick = (amount) => {
        setCustomAmount(amount);
    };

    const handleCustomAmountChange = (e) => {
        setCustomAmount(e.target.value);
    };

    const handleReceiptUpload = (e) => {
        const file = e.target.files[0];
        setPaymentReceipt(file);
    };

    const handlePaymentSubmission = async () => {
        if (!paymentReceipt) {
            alert('Please upload the payment receipt.');
            return;
        }
        if (!customAmount || isNaN(customAmount)) {
            alert('Please enter a valid amount.');
            return;
        }

        setLoading(true);
        setError(null);

        // Create FormData to send the file and amount
        const formData = new FormData();
        formData.append('receipt', paymentReceipt);
        formData.append('amount', customAmount);

        try {
            const response = await fetch(`https://sikandar.in/api/deposit/${localStorage.getItem('userId')}`, {
                method: 'POST',
                body: formData
            });

            const result = await response.json();

            if (response.ok) {
                alert('Payment receipt uploaded successfully!');
                onSubmit(result.balance); // Pass the updated balance to parent component
            } else {
                setError(result.message || 'Something went wrong');
            }
        } catch (err) {
            setError('Error submitting payment');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            padding: '20px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 1000,
            fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
            width: '350px',
            maxHeight: '80vh', // Set a maximum height for the popup
            overflowY: 'auto', // Enable vertical scrolling when content exceeds the height
            textAlign: 'center'
        }}>
            <h3 style={{ margin: '0 0 15px', fontSize: '18px', color: '#333' }}>Deposit Money</h3>
            <div style={{ marginBottom: '15px', textAlign: 'left' }}>
                <p style={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}>UPI ID:</p>
                <p style={{ fontSize: '16px', color: '#555' }}>sikandargame@ibl</p>
                {/* <p style={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}>Bank Details:</p>
                <p style={{ fontSize: '16px', color: '#555' }}>Account Holder Name: MAHESH BHIMRAO PAWAR</p>
                <p style={{ fontSize: '16px', color: '#555' }}>Account Number: 921010049823474</p>
                <p style={{ fontSize: '16px', color: '#555' }}>IFSC Code: UTIB0002137</p> */}
                <p style={{ fontSize: '16px', color: '#333', fontWeight: 'bold' }}>UPI Scanner:</p>
                <div style={{ width: '100%', margin: '0px', padding: '0px', display: 'flex', justifyContent: 'center' }}>
                    <img style={{ width: '80%' }} src={logo} alt="UPI Scanner" />
                </div>
            </div>
            <div>
                <p style={{ fontSize: '16px', color: '#333', fontWeight: 'bold', width: '100%', textAlign: 'start' }}>Select Amount:</p>
                <div style={{ marginBottom: '15px' }}>
                    {predefinedAmounts.map((amount) => (
                        <button
                            key={amount}
                            onClick={() => handleAmountClick(amount)}
                            style={{
                                margin: '5px',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                backgroundColor: amount == customAmount ? 'green' : '#007bff',
                                color: '#fff',
                                fontSize: '16px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = amount == customAmount ? 'green' : '#0056b3'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = amount == customAmount ? 'green' : '#007bff'}
                        >
                            ₹{amount}
                        </button>
                    ))}
                </div>
                <input
                    type="number"
                    value={customAmount}
                    onChange={handleCustomAmountChange}
                    placeholder="Custom Amount"
                    style={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        marginBottom: '15px',
                        fontSize: '16px'
                    }}
                />
                <div style={{ marginBottom: '15px' }}>
                    <label htmlFor="receiptUpload" style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', display: 'block', textAlign: 'center' }}>
                        Upload Payment Receipt:
                    </label>
                    <label
                        htmlFor="receiptUpload"
                        style={{
                            display: 'inline-block',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            backgroundColor: '#007bff',
                            color: '#fff',
                            fontSize: '16px',
                            cursor: 'pointer',
                            textAlign: 'center',
                            transition: 'background-color 0.3s ease',
                            margin: '5px'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                    >
                        Choose File
                    </label>
                    <input
                        type="file"
                        id="receiptUpload"
                        accept="image/*"
                        onChange={handleReceiptUpload}
                        style={{ display: 'none' }}
                    />
                    {paymentReceipt && (
                        <p style={{ fontSize: '14px', color: '#555', marginTop: '10px', textAlign: 'center' }}>
                            File: {paymentReceipt.name}
                        </p>
                    )}
                </div>
                {error && (
                    <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
                )}
                <a href="/deposit" className="footer-link">
                    * It may take up to 60 mins for the amount to be added to your wallet.
                    <u>Click here for more details!</u>
                </a>
                <div>
                    <button
                        onClick={handlePaymentSubmission}
                        style={{
                            margin: '5px',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#28a745',
                            color: '#fff',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease'
                        }}
                        disabled={loading}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#218838'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#28a745'}
                    >
                        {loading ? 'Submitting...' : 'Submit Payment'}
                    </button>
                    <button
                        onClick={onClose}
                        style={{
                            margin: '5px',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#dc3545',
                            color: '#fff',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#c82333'}
                        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#dc3545'}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WalletAmountPopup;