import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import GameDetail from './GameDetail'; // Import the GameDetail component

const TotalColorGame = () => {
    const [gameDetails, setGameDetails] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null); // State for selected game

    useEffect(() => {
        const fetchGameDetails = async () => {
            try {
                const response = await fetch('https://sikandar.in/api/games/details');
                const data = await response.json();
                setGameDetails(data);
            } catch (error) {
                console.error('Error fetching game details:', error);
            }
        };

        fetchGameDetails();
    }, []);

    const handleGameClick = async (gameId) => {
        try {
            const response = await fetch(`https://sikandar.in/api/games-details/${gameId}`);
            const data = await response.json();
            setSelectedGame(data);
        } catch (error) {
            console.error('Error fetching game details:', error);
        }
    };

    const goBack = () => {
        setSelectedGame(null); // Reset the selected game to null to go back to the list
    };

    return (
        <div>
            {selectedGame ? (
                <GameDetail game={selectedGame} goBack={goBack} /> // Pass goBack to GameDetail
            ) : (
                <div>
                    <h2>Total Color Game</h2>
                    <Table striped bordered hover style={{ overflow: 'scroll' }}>
                        <thead>
                            <tr>
                                <th>Game ID</th>
                                <th>Winner</th>
                                <th>Timing</th>
                                <th>Total Users</th>
                                <th>Total Betting Amount</th>
                                <th>Total Winning Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gameDetails.filter((game) => game.gameType == 'Color' && game.totalBetAmount > 0).map((game) => (
                                <tr key={game.gameId} onClick={() => handleGameClick(game.gameId)} style={{ cursor: 'pointer' }}>
                                    <td>{game.gameId}</td>
                                    <td>{game.winning}</td>
                                    <td>{new Date(game.timing).toLocaleString()}</td>
                                    <td>{game.totalUsers}</td>
                                    <td>₹{game.totalBetAmount}</td>
                                    <td>₹{game.totalWinningBetAmount * 9}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default TotalColorGame;