import React, { useState } from 'react';
import './TransactionsScreen.css';

const TransactionsScreen = ({ onScreenChange }) => {
    const [balance, setBalance] = useState(5000); // Initial balance for demonstration
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [amount, setAmount] = useState('');
    const [transactionType, setTransactionType] = useState('');

    const handleDeposit = () => {
        if (amount > 0) {
            const newBalance = balance + Number(amount);
            setBalance(newBalance);
            addTransaction('Deposit', amount);
            setAmount('');
        }
    };

    const handleWithdraw = () => {
        if (amount > 0 && amount <= balance) {
            const newBalance = balance - Number(amount);
            setBalance(newBalance);
            addTransaction('Withdraw', amount);
            setAmount('');
        }
    };

    const addTransaction = (type, amount) => {
        const newTransaction = {
            id: transactionHistory.length + 1,
            type: type,
            amount: Number(amount),
            date: new Date().toLocaleString(),
        };
        setTransactionHistory([newTransaction, ...transactionHistory]);
    };

    return (
        <div className="transactions-container">
            <h1 className="title">Account Transactions</h1>
            <div className="balance-section">
                <h2>Available Balance: ${balance}</h2>
            </div>

            <div className="transaction-actions">
                <div className="transaction-card">
                    <h3>Deposit Funds</h3>
                    <input
                        type="number"
                        placeholder="Enter amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="transaction-input"
                    />
                    <button
                        className="transaction-button"
                        onClick={handleDeposit}
                        disabled={!amount}
                    >
                        Deposit
                    </button>
                </div>

                <div className="transaction-card">
                    <h3>Withdraw Funds</h3>
                    <input
                        type="number"
                        placeholder="Enter amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className="transaction-input"
                    />
                    <button
                        className="transaction-button"
                        onClick={handleWithdraw}
                        disabled={!amount || amount > balance}
                    >
                        Withdraw
                    </button>
                </div>
            </div>

            <div className="transaction-history">
                <h2>Transaction History</h2>
                {transactionHistory.length === 0 ? (
                    <p>No transactions yet.</p>
                ) : (
                    <ul>
                        {transactionHistory.map((transaction) => (
                            <li key={transaction.id}>
                                {transaction.date} - {transaction.type}: ${transaction.amount}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default TransactionsScreen;